import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";

const storage = getStorage();

export const uploadResumeToFirebase = async (
  resume: File,
  jobId: string,
  name: string
) => {
  console.log("gettung the resume url");
  const storageRef = ref(
    storage,
    `/files/${new Date().getTime()}__${jobId}_${name}`
  );
  const uploadTask = uploadBytesResumable(storageRef, resume);

  // Wait for the upload to complete
  await new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Handle progress here if needed
      },
      (error) => reject(error),
      () => resolve(null)
    );
  });

  // Get the download URL after upload is complete
  const url = await getDownloadURL(uploadTask.snapshot.ref);
  console.log("url", url);
  return url;
};

export const deleteResumeFromFirebase = async (fileUrl: string) => {
  try {
    // Create a reference to the file using the full URL
    const fileRef = ref(storage, fileUrl);

    // Delete the file
    await deleteObject(fileRef);
    console.log("file deleted");
    return true;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
};
