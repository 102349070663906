import React, { useState, useEffect, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StateColors from "../../applicants-sidebar/StateColors";

interface FilterDropdownProps {
  options: string[];
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  highlightOptions?: boolean;
  optionsWithColors?: { name: string; color: string; order: number }[];
}
function FilterDropdown({
  options,
  state,
  setState,
  placeholder,
  highlightOptions,
  optionsWithColors,
}: FilterDropdownProps) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="filter-dropdown-container" ref={dropdownRef}>
      <div className=" filter-dropdown">
        <div
          className={`asdd-box-header filter-dropdown-header  ${
            open ? "asdd-box-header-open" : ""
          }`}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="flx" style={{ alignItems: "baseline" }}>
            <span
              style={{
                color: "rgba(0,0,0,0.6)",
                marginRight: "5px",
                fontSize: "16px",
              }}
            >
              {placeholder ? placeholder + ":" : "Estado:"}
            </span>
            {highlightOptions && optionsWithColors ? (
              <StateColors state={state} />
            ) : (
              <span style={{ fontSize: "16px" }}>{state}</span>
            )}
          </div>
          <ArrowDropDownIcon
            style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </div>

        {highlightOptions && optionsWithColors ? (
          <div className={`filter-dropdown-box ${!open && "hide"} `}>
            {optionsWithColors.map((option, index) => {
              return (
                <div
                  className="asdd-box-item"
                  key={index}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    setState(option.name);
                    setOpen(false);
                  }}
                >
                  <StateColors state={option.name} color={option.color} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className={`filter-dropdown-box ${!open && "hide"} `}>
            {options.map((option, index) => {
              return (
                <div
                  className="asdd-box-item"
                  key={index}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    setState(option);
                    setOpen(false);
                  }}
                >
                  <span
                    style={{
                      padding: "5px 12px",
                      fontSize: "16px",
                    }}
                  >
                    {option}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterDropdown;
