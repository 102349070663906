import {
  AutomationTrigger,
  AutomationAction,
} from "../../typescript/interfaces/AutomationTypes";

const checkTriggersComplete = (trigger: AutomationTrigger): boolean => {
  // Check basic trigger requirements
  if (!trigger.jobId || !trigger.stateId || !Array.isArray(trigger.actions)) {
    return false;
  }

  // Check each action
  return trigger.actions.every((action: AutomationAction) => {
    if (!action.type || !action.config) {
      return false;
    }

    // Check specific requirements based on action type
    switch (action.type) {
      case "MOVE_APPLICANT":
        return !!action.config.targetStateOption;

      case "SEND_EMAIL":
        return !!(action.config.emailTemplate && action.config.recipientType);

      default:
        return false;
    }
  });
};

export default checkTriggersComplete;
