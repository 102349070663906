import React from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import "../../assets/styles/dashboard/applicants.css";
import ApplicantPill from "./ApplicantPill";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";

interface ApplicantSidebarProps {
  aplicants: ApplicantInt[];

  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ) => Promise<void>;
  selectedApplicant: ApplicantInt;
  setSelectedApplicant: (applicant: ApplicantInt) => void;
  stateOptions: applicantStateOption[];
}

function ApplicantSidebar(props: ApplicantSidebarProps) {
  return (
    <div className="applicant-sidebar-container mobile-only">
      <div className="applicant-sidebar-box">
        <div className="applicant-sidebar-left-box hide-scrollbar">
          {props.aplicants.map((aplicant, index) => {
            return (
              <ApplicantPill
                key={aplicant._id}
                applicant={aplicant}
                setSelectedApp={props.setSelectedApplicant}
                selected={aplicant === props.selectedApplicant}
                setApplicantsState={props.setApplicantsState}
                stateOptions={props.stateOptions}
              />
            );
          })}
        </div>
        {/* 
        <div className="applicant-sidebar-right-box desktop-only">
          {props.selectedApplicant && (
            <ApplicantSideInfo
              selected={props.selectedApplicant}
              setApplicantsState={props.setApplicantsState}
              stateOptions={props.stateOptions}
            />
          )}
        </div> */}

        {/* popup  */}
      </div>
    </div>
  );
}

export default ApplicantSidebar;
