import { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";

import Positions from "../components/dashboard/Positions";
import Applicants from "../components/dashboard/Applicants";

import { getAuth } from "firebase/auth";
import {} from "../typescript/interfaces/JobInterface";
import LoadingWidget from "../components/widgets/LoadingWidget";
import { useLocation } from "react-router-dom";
import { getBusinessUserPosts } from "../utils/businessUserFunctions";
import CompanyForm from "../components/dashboard/forms/CompanyForm";
import { Alert, Snackbar } from "@mui/material";
import { getCompanyByUser } from "../utils/companyFunctions";
import { useUser } from "../components/routing/UserContext";
import { useJobPositions } from "../components/routing/JobPositionsContext";

import CompanyPageBuilder from "../components/dashboard/companyPage/CompanyPageBuilder";
import ApplicantList from "../components/dashboard/ApplicantList";

function Dashboard() {
  const { userData, setUserData, companyData, setCompanyData } = useUser();
  const { positions, isLoading, refreshPositions } = useJobPositions();
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [selectedPage, setSelectedPage] = useState(() => {
    return localStorage.getItem("currentPage") || "vacantes";
  });
  const [postsAvailable, setPostsAvailable] = useState<number>(0);
  const [companyFormOpen, setCompanyFormOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const alert = useLocation().state?.alert;
  const auth = getAuth();
  const [showJobAlert, setShowJobAlert] = useState(false);
  const [showEraseJobAlert, setShowEraseJobAlert] = useState(false);
  const [jobAlertMessage, setJobAlertMessage] = useState("");
  const [jobAlertSeverity, setJobAlertSeverity] = useState<"success" | "error">(
    "success"
  );

  const [selectedJobID, setSelectedJobID] = useState<string | null>(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobSuccess = searchParams.get("success");

  const getPostsAvailable = useCallback(async () => {
    if (auth.currentUser) {
      try {
        setIsLoadingPosts(true);
        const user = auth.currentUser.email;
        const posts = await getBusinessUserPosts(user as string);
        setUserData(posts);
        setCompanyFormOpen(!posts.companyId);
      } catch (error) {
        console.error("Error fetching available posts:", error);
      } finally {
        setIsLoadingPosts(false);
      }
    }
  }, [auth, setUserData]);

  useEffect(() => {
    getPostsAvailable();
  }, [getPostsAvailable]);

  useEffect(() => {
    // Store the current page number in localStorage
    localStorage.setItem("currentPage", selectedPage);
  }, [selectedPage]);

  useEffect(() => {
    if (alert) {
      setShowAlert(true);
      window.history.replaceState({}, document.title);
    }
  }, [alert]);

  useEffect(() => {
    // when logging out reset the userData and companyData
    if (userData?.companyId) {
      const fetchCompanyData = async () => {
        try {
          const company = await getCompanyByUser(userData.companyId as string);
          setPostsAvailable(company.maxPostsAllowed - company.currentPosts);
          setCompanyData(company);
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };
      fetchCompanyData();
    }
  }, [userData, setCompanyData]);

  useEffect(() => {
    if (jobSuccess !== null) {
      setShowJobAlert(true);
      if (jobSuccess === "true") {
        setJobAlertMessage("Vacante publicada exitosamente");
        setJobAlertSeverity("success");
        localStorage.removeItem("draftJob");
        localStorage.removeItem("jobStep");
      } else {
        setJobAlertMessage("Error al publicar la vacante. Intenta nuevamente.");
        setJobAlertSeverity("error");
      }
      // Clean up the URL
      window.history.replaceState({}, "", location.pathname);
    }
  }, [jobSuccess, location.pathname]);

  const refreshDashboard = useCallback(() => {
    refreshPositions();
    getPostsAvailable();
  }, [refreshPositions, getPostsAvailable]);

  const handleJobSelect = (jobId: string) => {
    setSelectedJobID(jobId);
    setSelectedPage("aplicantesEmpresa");
    console.log(selectedJobID);
  };

  return (
    <div
      className="w100 flx flx-col"
      style={{
        height: "100dvh",
      }}
    >
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowAlert(false)}
          severity={
            alert?.message === "Empresa creada exitosamente"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {alert?.message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showJobAlert}
        autoHideDuration={6000}
        onClose={() => setShowJobAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowJobAlert(false)}
          severity={jobAlertSeverity}
          sx={{ width: "100%" }}
        >
          {jobAlertMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showEraseJobAlert}
        autoHideDuration={6000}
        onClose={() => setShowEraseJobAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowEraseJobAlert(false)}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          Error al eliminar la vacante
        </Alert>
      </Snackbar>

      <Navbar
        scrollPast={true}
        lockNavbar={true}
        highlightDash={true}
        hideDash={true}
        setPage={setSelectedPage}
        subbarLoading={isLoadingPosts}
        jobsAvailable={postsAvailable}
        hidePanel={true}
        blockLogoClick={true}
        showProfileSettings={true}
      />

      {isLoadingPosts ? (
        <div className="flx flx-center mt-25">
          <LoadingWidget loading={true} />
        </div>
      ) : companyFormOpen ? (
        <CompanyForm onCompanyCreated={refreshDashboard} />
      ) : (
        <div className="flx flx-col flex-1" style={{ overflow: "auto" }}>
          <div className="flx flx-col flex-1" style={{ overflow: "auto" }}>
            {companyData ? (
              selectedPage === "vacantes" ? (
                <Positions
                  positions={positions || []}
                  getPositions={refreshPositions}
                  postsAvailable={postsAvailable}
                  companyData={companyData}
                  refreshDashboard={refreshDashboard}
                  setShowEraseJobAlert={setShowEraseJobAlert}
                  onJobSelect={handleJobSelect}
                />
              ) : selectedPage === "aplicantes" ? (
                <ApplicantList companyId={companyData._id as string} />
              ) : selectedPage === "pagina-empresa" ? (
                <CompanyPageBuilder company={companyData} />
              ) : selectedPage === "aplicantesEmpresa" ? (
                positions && positions.length > 0 ? (
                  <Applicants
                    jobs={positions || []}
                    currentJobID={selectedJobID}
                    getPositions={refreshPositions}
                  />
                ) : null
              ) : null
            ) : (
              <div className="flx flx-center mt-25">
                <LoadingWidget loading={isLoading} />
              </div>
            )}
          </div>
        </div>
      )}

      {/* 
      <SignOut /> */}
    </div>
  );
}

export default Dashboard;
