import { Auth, signOut } from "firebase/auth";

export const handleSignOutGlobal = async (auth: Auth) => {
  // Sign out from Firebase
  await signOut(auth);

  // Clear all relevant localStorage items
  const localStorageKeys = [
    // User related
    "userData",
    "companyData",
    // Job related
    "selectedJobId",
    "draftJob",
    "jobStep",
    // Add any other keys you want to clear
  ];

  localStorageKeys.forEach((key) => localStorage.removeItem(key));
};
