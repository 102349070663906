import { Education } from "../../typescript/interfaces/AppInterface";
import "../../assets/styles/job-apply/educationSection.css";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import StandardDropDown from "../standard/dropdown/StandardDropDown";

interface EducationSectionProps {
  educationList: Education[];
  handleEducationChange: (
    index: number,
    field: keyof Education,
    value: string | boolean
  ) => void;
  handleEducationDelete: (index: number) => void;
  addEducation: () => void;
}

// Add this constant for education level options
const EDUCATION_LEVEL_OPTIONS = [
  { value: "Educación Básica", label: "Educación Básica" },
  { value: "Bachillerato", label: "Bachillerato" },
  { value: "Técnico", label: "Técnico" },
  { value: "Licenciatura", label: "Licenciatura" },
  { value: "Maestría", label: "Maestría" },
  { value: "Doctorado", label: "Doctorado" },
  { value: "Certificación", label: "Certificación" },
  { value: "Curso", label: "Curso" },
];

function EducationSection({
  educationList,
  handleEducationChange,
  handleEducationDelete,
  addEducation,
}: EducationSectionProps) {
  const handleInputChange = (
    index: number,
    field: keyof Education,
    value: string | boolean
  ) => {
    handleEducationChange(index, field, value);
  };

  // add the presente to remove fecha fn

  return (
    <div className="education-section postjob-gray-container">
      <div className="flx flx-center experience_education-add-button">
        <div className="education-section__title" style={{ fontSize: "18px" }}>
          {" "}
          Educación
        </div>
        <div className="ex-ed-add-button" onClick={addEducation}>
          <AddIcon /> añadir
        </div>
      </div>
      <div className="education-section__items">
        {educationList.map((edu, index) => (
          <div key={index} className="education-item">
            {/* pass index to erase  */}
            <div
              className="education-item__delete-button button-hover"
              onClick={() => handleEducationDelete(index)}
            >
              <DeleteOutlineIcon />
            </div>
            <div>
              <div>Institución</div>
              <input
                className="education-input-item search-pill-input "
                value={edu.institucion}
                onChange={(e) =>
                  handleInputChange(index, "institucion", e.target.value)
                }
              />
            </div>
            <div>
              <div>Titulo</div>
              <input
                className=" education-input-item education-item__title search-pill-input"
                value={edu.titulo}
                onChange={(e) =>
                  handleInputChange(index, "titulo", e.target.value)
                }
              />
            </div>
            <div>
              <div>Nivel educativo</div>
              <StandardDropDown
                options={EDUCATION_LEVEL_OPTIONS}
                value={{
                  value: edu.nivel_educativo || "",
                  label: edu.nivel_educativo || "Selecciona nivel educativo",
                }}
                onSelect={(selected) =>
                  handleEducationChange(
                    index,
                    "nivel_educativo",
                    selected.value
                  )
                }
              />
            </div>
            <div className=" education-item__dates-container">
              <div className="flx" style={{ gap: "10px" }}>
                <div className="flx flx-col">
                  <div>Fecha de inicio</div>
                  <DatePicker
                    value={edu.fecha_inicio ? dayjs(edu.fecha_inicio) : null}
                    onChange={(newValue) =>
                      handleInputChange(
                        index,
                        "fecha_inicio",
                        newValue?.format("YYYY-MM-DD") || ""
                      )
                    }
                  />
                </div>
                <div className="flx flx-col">
                  <div>Fecha de fin</div>
                  {edu.estudiando_actualmente ? (
                    <div className="date-picker-placeholder">Presente</div>
                  ) : (
                    <DatePicker
                      value={edu.fecha_fin ? dayjs(edu.fecha_fin) : null}
                      onChange={(newValue) =>
                        handleInputChange(
                          index,
                          "fecha_fin",
                          newValue?.format("YYYY-MM-DD") || ""
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div
                className="flx"
                style={{ alignItems: "center", gap: "10px" }}
              >
                {"Actualmente estudiando"}
                {edu.estudiando_actualmente ? (
                  <div
                    className="tof-dot-true button-hover"
                    onClick={() => {
                      handleInputChange(index, "estudiando_actualmente", false);
                    }}
                  >
                    <CheckOutlinedIcon
                      style={{ color: "white", fontSize: "18px" }}
                    />
                  </div>
                ) : (
                  <div
                    className="tof-question-dot button-hover"
                    onClick={() => {
                      handleInputChange(index, "estudiando_actualmente", true);
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default EducationSection;
