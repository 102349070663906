import axios from "axios";

export const getEmailTemplates = async (companyId: string) => {
  try {
    const response = await axios.get(
      `https://getemailtemplates-gi2cautoja-rj.a.run.app?companyId=${companyId}`
    );

    return response.data.result;
  } catch (error) {
    console.error("Error fetching templates:", error);
    return [];
  }
};
