import React, { useEffect, useState } from "react";
import { AutomationAction } from "../../../../typescript/interfaces/AutomationTypes";
import { EmailTemplate } from "../../../../typescript/interfaces/EmailType";
import { upsertEmailTemplate } from "../../../../utils/email/upsertEmailTemplate";
import { getEmailTemplates } from "../../../../utils/email/getEmailTemplates";
import { useUser } from "../../../routing/UserContext";
import TextEditor2 from "../../../text-editor/TextEditor2";

interface EmailAutomationTemplateProps {
  action: AutomationAction;
  updateAction: (updates: Partial<AutomationAction>) => void;
}

function EmailAutomationTemplate({
  action,
  updateAction,
}: EmailAutomationTemplateProps) {
  const { companyData } = useUser();
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [newTemplate, setNewTemplate] = useState<Partial<EmailTemplate>>({
    name: "",
    subject: "",
    content: "",
  });
  const [savingTemplateLoading, setSavingTemplateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      if (!companyData?._id) return;

      try {
        setIsLoading(true);
        setError(null);
        const response = await getEmailTemplates(companyData._id);
        setTemplates(response);
      } catch (error) {
        setError(
          "Error al cargar las plantillas. Por favor, intente nuevamente."
        );
        console.error("Error fetching templates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [companyData?._id]);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Destinatario
        </label>
        <select
          value={action.config.recipientType || ""}
          onChange={(e) =>
            updateAction({
              config: {
                ...action.config,
                recipientType: e.target.value as "APPLICANT" | "TEAM" | "BOTH",
              },
            })
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        >
          <option value="APPLICANT">Candidato</option>
          <option value="TEAM">Equipo</option>
          <option value="BOTH">Ambos</option>
        </select>
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Plantilla de Email
        </label>
        {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
        <select
          value={isNewTemplate ? "new" : action.config.emailTemplate || ""}
          onChange={(e) => {
            if (e.target.value === "new") {
              setIsNewTemplate(true);
            } else {
              setIsNewTemplate(false);
              updateAction({
                config: {
                  ...action.config,
                  emailTemplate: e.target.value,
                },
              });
            }
          }}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          disabled={isLoading}
        >
          <option value="">
            {isLoading ? "Cargando plantillas..." : "Seleccionar plantilla..."}
          </option>
          {!isLoading &&
            templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          <option value="new">+ Crear nueva plantilla</option>
        </select>
      </div>

      {isNewTemplate && (
        <div className="space-y-4 p-4 border border-gray-200 rounded-md">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Nombre de la plantilla
            </label>
            <input
              type="text"
              value={newTemplate.name}
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, name: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Asunto
            </label>
            <input
              type="text"
              value={newTemplate.subject}
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, subject: e.target.value })
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Contenido
            </label>
            <TextEditor2
              editorName=""
              htmlValue={newTemplate.content || ""}
              setHTMLValue={(html) =>
                setNewTemplate({ ...newTemplate, content: html })
              }
            />
          </div>

          <button
            onClick={async (event) => {
              event.preventDefault();
              setSavingTemplateLoading(true);
              try {
                newTemplate.companyId = companyData?._id || "";
                const resultData = await upsertEmailTemplate(
                  newTemplate as EmailTemplate
                );
                newTemplate.id = resultData.id;

                setTemplates([...templates, newTemplate as EmailTemplate]);
                setIsNewTemplate(false);
                updateAction({
                  config: {
                    ...action.config,
                    emailTemplate: newTemplate.id,
                  },
                });
              } catch (error) {
                console.error("Error saving new template:", error);
              } finally {
                setSavingTemplateLoading(false);
              }
            }}
            disabled={
              !newTemplate.name || !newTemplate.subject || !newTemplate.content
            }
            className={`mt-4 w-full px-4 py-2 text-sm font-medium ${
              !newTemplate.name || !newTemplate.subject || !newTemplate.content
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "text-laburo-green border border-laburo-green hover:bg-gray-100"
            } rounded-md transition-colors duration-150 ease-in-out`}
          >
            {savingTemplateLoading ? (
              <span>Guardando Plantilla </span>
            ) : (
              <span>Guardar Plantilla</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default EmailAutomationTemplate;
