import React, { useState, useRef, useEffect } from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import ApplicantCardPipeline from "./cards/ApplicantCardPipeline";
import ApplicantSideInfo from "../applicants-sidebar/ApplicantSideInfo";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";
import { AutoFixHigh } from "@mui/icons-material";

interface ApplicantPipelineProps {
  applicants: ApplicantInt[];
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    applicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ) => Promise<void>;
  stateOptions: applicantStateOption[];
  selectedApplicant: ApplicantInt;
  setSelectedApplicant: (applicant: ApplicantInt) => void;
  setShowAutomationForm: (stateId: string) => void;
}

const ApplicantPipeline: React.FC<ApplicantPipelineProps> = ({
  applicants,
  setApplicantsState,
  stateOptions,
  selectedApplicant,
  setSelectedApplicant,
  setShowAutomationForm,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const columns = {
  //   Nuevos: applicants.filter((a) => a.state === "Nuevos"),
  //   Guardados: applicants.filter((a) => a.state === "Guardados"),
  //   Rechazados: applicants.filter((a) => a.state === "Rechazados"),
  // };

  const columns = React.useMemo(() => {
    return stateOptions.reduce((acc, state) => {
      acc[state.name] = applicants.filter((a) => a.state === state.id);
      return acc;
    }, {} as Record<string, ApplicantInt[]>);
  }, [stateOptions, applicants]);

  const handleDragStart = (e: React.DragEvent, applicant: ApplicantInt) => {
    e.dataTransfer.setData("applicantId", applicant._id as string);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent, targetColumnName: string) => {
    e.preventDefault();
    const applicantId = e.dataTransfer.getData("applicantId");
    const applicant = applicants.find((a) => a._id === applicantId);
    const targetState = stateOptions.find(
      (state) => state.name === targetColumnName
    );

    if (applicant && targetState) {
      await setApplicantsState(
        e as any,
        applicant,
        targetState.id,
        targetState
      );
    }
  };

  const handleApplicantClick = (applicant: ApplicantInt) => {
    setIsPopupOpen(true);
    setSelectedApplicant(applicant);
  };

  return (
    <>
      <div className="flex gap-6 w-full mx-auto overflow-x-auto scrollbar-hide flex-1 pb-5 ">
        {stateOptions.map((stateOption) => {
          const columnApplicants = columns[stateOption.name];

          return (
            <div
              key={stateOption.id}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, stateOption.name)}
              className="flex-1 min-w-[300px] bg-gray-100 rounded-lg p-4"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">{stateOption.name}</h3>
                {stateOption.automationTriggerId && (
                  <AutoFixHigh
                    className="text-purple-500 hover:cursor-pointer"
                    style={{ fontSize: "20px" }}
                    onClick={() => setShowAutomationForm(stateOption.id)}
                  />
                )}
              </div>
              <div className="overflow-y-auto scrollbar-hide h-[calc(100%-3rem)] flex flex-col gap-2">
                {columnApplicants.map((applicant) => (
                  <ApplicantCardPipeline
                    key={applicant._id}
                    applicant={applicant}
                    handleDragStart={handleDragStart}
                    onClick={() => handleApplicantClick(applicant)}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {selectedApplicant && isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[110]">
          <div
            ref={popupRef}
            className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto relative"
          >
            <button
              className="float-right text-gray-500 hover:text-gray-700 absolute top-3 right-5 z-[10] text-lg"
              onClick={() => setIsPopupOpen(false)}
            >
              ✕
            </button>
            <ApplicantSideInfo
              selected={selectedApplicant || applicants[0]}
              setApplicantsState={setApplicantsState}
              stateOptions={stateOptions}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicantPipeline;
