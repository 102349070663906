import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/postjobs.css";
import AutocompleteLocation from "../AutocompleteLocation";
import { LocationData } from "../../typescript/interfaces/Location";
import TrueorFalse from "./TrueorFalse";

import { JobInt } from "../../typescript/interfaces/JobInterface";

import { Alert, Switch } from "@mui/material"; // Import Switch
import useAuth from "../../utils/useAuth";
import CustomQuestions from "./CustomQuestions";
import { Question } from "../../typescript/interfaces/CustomQuestionInt";
import LocationMapSelector from "./LocationMapSelector";

import { coordResultsProps } from "../../typescript/interfaces/MapLocationInt";
import TextEditor2 from "../text-editor/TextEditor2";
import ApplicantOptionSelector from "./ApplicantOptionSelector";

interface CreateJobProps {
  job: JobInt;
  setJob: React.Dispatch<React.SetStateAction<JobInt>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

function CreateJob(props: CreateJobProps) {
  const [title, setTitle] = useState(props.job.title);
  const [company, setCompany] = useState(props.job.company);
  const [mapResults, setMapResults] = useState<coordResultsProps>();
  const { user } = useAuth();

  const [selectedLocation, setSelectedLocation] = useState<
    LocationData | undefined
  >(
    props.job.location.city && props.job.location.country
      ? props.job.location
      : undefined
  );

  const [description, setDescription] = useState(props.job.description);
  const [inPerson, setInPerson] = useState(props.job.inPerson);
  const [fullTime, setFullTime] = useState(props.job.fullTime);
  // const [number, setNumber] = useState(props.job.number);

  // pass this for setter
  const [questions, setQuestions] = useState<Question[]>(props.job.questions);
  const [salaryRange, setSalaryRange] = useState<number[]>(
    props.job.salaryRange.length ? props.job.salaryRange : []
  );
  const [salaryError, setSalaryError] = useState<string | null>(null);
  // const [image, setImage] = useState<any>(null);
  const [destination, setDestination] = useState(props.job.recieveEmail);
  const [applicantOptions, setApplicantoptions] = useState(
    props.job.applicantStateOptions
  );
  // const hiddenFileInput = useRef<any>(null);
  const [formAlert, setformAlert] = useState(false);
  const increaseStep = () => {
    //  remove empty questions

    const QuestArray = questions.filter(
      (question, i) => question.question !== ""
    );
    console.log(salaryRange);
    props.setJob({
      ...props.job,
      title,
      company,
      description,
      location: selectedLocation as LocationData,
      inPerson,
      fullTime,
      recieveViaEmail: true,
      // number,
      recieveEmail: destination,
      userId: user?.email || undefined,
      questions: QuestArray,
      salaryRange,
      mapResults,
      applicantStateOptions: applicantOptions,
    });
    props.setStep(2);
  };

  const showEmptyFields = () => {
    let names = ["Posición", "Empresa", "Ubicación", "Destino"];
    let form = [title, company, selectedLocation, destination];

    names.push("Descripción");
    form.push(description);

    if (proximity) {
      names.push("Filtro de Proximidad");
      form.push(
        mapResults?.center?.lat !== undefined &&
          mapResults?.center?.lng !== undefined
          ? `${mapResults.center.lat},${mapResults.center.lng}`
          : undefined
      );
    }

    return form.map((val, index) => {
      if (!val) {
        return <div key={index}>{names[index]}</div>;
      }
      return null;
    });
  };

  const handleSalaryRange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value ? parseInt(e.target.value) : null;
    let newSalaryRange = [...salaryRange];
    if (value !== null) {
      newSalaryRange[index] = value;
    } else {
      newSalaryRange = newSalaryRange.filter((_, i) => i !== index);
    }
    setSalaryRange(newSalaryRange);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // add questions req and add to job

    event.preventDefault();
    console.log(applicantOptions);
    // Validate salary range only if both values are provided
    if (salaryRange.length === 2) {
      if (
        salaryRange[0] >= salaryRange[1] ||
        salaryRange[0] < 0 ||
        salaryRange[1] < 0
      ) {
        setSalaryError(
          "El salario mínimo debe ser menor que el salario máximo."
        );
        window.scrollTo(0, 0);
        return;
      }
    }
    setSalaryError(null);

    // check if description needed

    let descriptionReq;
    if (!description) {
      descriptionReq = false;
    } else {
      descriptionReq = true;
    }
    let locationReq;
    if (
      proximity &&
      mapResults?.center?.lat === undefined &&
      mapResults?.center?.lng === undefined
    ) {
      locationReq = false;
    } else {
      locationReq = true;
    }

    if (
      title &&
      company &&
      selectedLocation &&
      destination &&
      descriptionReq &&
      locationReq
    ) {
      increaseStep();
    } else {
      window.scrollTo(0, 0);
      setformAlert(true);
    }
  };
  const [proximity, setProximity] = useState<boolean>(false);
  const [mapOpen, setMapOpen] = useState<boolean>(false);
  const proximityRef = useRef<HTMLDivElement>(null); // Add this ref

  // Add this useEffect hook
  useEffect(() => {
    if (proximity) {
      const proximityDiv = document.getElementById("proximity-section");

      if (proximityDiv) {
        const yOffset = -75; // Adjust the offset as needed
        const y =
          proximityDiv.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [proximity]);

  // Add this useEffect hook
  // useEffect(() => {
  //   const handleScrollLock = () => {
  //     if (mapOpen && window.innerWidth < 750) {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "auto";
  //     }
  //   };

  //   handleScrollLock();
  //   window.addEventListener("resize", handleScrollLock);

  //   return () => {
  //     window.removeEventListener("resize", handleScrollLock);
  //     document.body.style.overflow = "auto";
  //   };
  // }, [mapOpen]);

  const handleMapSubmit = () => {
    // set the validate map to true and display it for smaller screens
  };

  return (
    <div className="w100">
      {formAlert && (
        <div className="w100 mb-25">
          <Alert severity="error" style={{ borderRadius: "10px" }}>
            <div className="">
              Por favor completa estos campos: {showEmptyFields()}
            </div>
          </Alert>
        </div>
      )}

      {salaryError && (
        <div className="w100 mb-25">
          <Alert severity="error" style={{ borderRadius: "10px" }}>
            <div className="">{salaryError}</div>
          </Alert>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="postjob-gray-container  mb-25">
          <div className="postjob-container-title">Destino</div>

          {/* manage the input so that it clears if button switches  */}

          <div className="w100">
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              Correo Para Recibir Postulantes
            </div>

            <div className="search-pill">
              {/* if input type email then email otherwise url */}
              <input
                type={"email"}
                className="search-pill-input "
                value={destination}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDestination(e.target.value);
                  e.target.setCustomValidity("");
                }}
              />
            </div>
          </div>
        </div>

        <div className="postjob-gray-container">
          <div className="w100">
            <div className="postjob-container-title">Información</div>

            <div className="w100">
              <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                {" "}
                Posición
              </div>

              <div className="search-pill">
                <input
                  type="text"
                  className="search-pill-input "
                  value={title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(e.target.value);
                  }}
                  maxLength={45}
                />
              </div>
            </div>

            <div className="w100">
              <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                {" "}
                Empresa
              </div>

              <div className="search-pill">
                <input
                  type="text"
                  className="search-pill-input "
                  value={company}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCompany(e.target.value);
                  }}
                  maxLength={30}
                />
              </div>
            </div>

            {/* <div className="w100">
              <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                {" "}
                Teléfono{" "}
                <span style={{ fontSize: "14px", color: "gray" }}>
                  (No visible para candidatos)
                </span>
              </div>

              <div className="search-pill">
                <input
                  type="text"
                  className="search-pill-input "
                  value={number}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNumber(e.target.value);
                  }}
                  maxLength={12}
                />
              </div>
            </div> */}

            <div className="w100">
              <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                Ubicación{" "}
                <span style={{ color: "gray", fontSize: "14px" }}>
                  (Selecciona una ubicación del menu)
                </span>
              </div>

              <AutocompleteLocation
                setSelectedLocation={setSelectedLocation}
                value={selectedLocation}
              />
            </div>

            {/*  */}
            {/* add logo */}
            {/* <div className="postjob-upload-logo-container">
            {image ? (
              <div>
                <div className="postjob-logo-container">
                  <img
                    alt="Por favor intente de nuevo"
                    src={URL.createObjectURL(image)}
                    className="postjob-logo-image"
                  />
                </div>

                <button onClick={() => setImage(null)}>Eliminar</button>
              </div>
            ) : (
              <div className="postjob-default-logo">
                <StoreIcon style={{ fontSize: "35px" }} />{" "}
              </div>
            )}

            <div onClick={handleClick} className="postjob-upload-button-button">
              Select Image
            </div>

            <input
              type="file"
              name="myImage"
              onChange={(event: any) => {
                console.log(event.target.files[0]);
                setImage(event.target.files[0]);
              }}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
          </div> */}

            {/*  Set descritpion*/}

            <TextEditor2
              editorName="Descripción"
              htmlValue={description}
              setHTMLValue={setDescription}
            />
          </div>
        </div>

        <ApplicantOptionSelector
          selectedApplicantOptions={applicantOptions}
          setSelectedApplicantOptions={setApplicantoptions}
        />

        <div
          className="postjob-gray-container mt-25"
          id="proximity-section"
          ref={proximityRef}
          style={{ position: "relative" }}
        >
          <div
            className="postjob-container-title"
            style={{ marginBottom: "10px" }}
          >
            Filtro de Proximidad (Opcional)
          </div>
          <div style={{ marginBottom: "15px", lineHeight: "1.4" }}>
            Este filtro te permite definir un área geográfica específica para
            recibir candidatos. Activa la opción para delimitar la zona en el
            mapa donde prefieres que residan los aplicantes.
          </div>
          <div
            className="flx "
            style={{ alignItems: "center", marginBottom: "5px" }}
          >
            <Switch
              checked={proximity}
              onChange={(e) => {
                setProximity(e.target.checked);

                setMapOpen(e.target.checked);
                if (!e.target.checked) {
                  setMapResults(undefined); // Reset mapResults when proximity is turned off
                }
              }}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#15BC7F", // Thumb color when checked
                  "&:hover": {
                    backgroundColor: "rgba(21, 188, 127, 0.08)", // Hover color
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#15BC7F", // Track color when checked
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "#f50057", // Track color when unchecked
                },
              }}
              inputProps={{ "aria-label": "toggle proximity filter" }}
            />
            <span
              style={{
                marginLeft: "10px",
                fontWeight: "bold",
                color: proximity ? "#15BC7F" : "red",
              }}
            >
              {proximity ? "Filtro activado" : "Filtro desactivado"}
            </span>
          </div>
          {mapOpen ? (
            <LocationMapSelector
              setProximity={setProximity}
              setMapOpen={setMapOpen}
              onSubmit={handleMapSubmit}
              setMapResults={setMapResults}
            />
          ) : mapResults && proximity ? (
            <div className="laburo-green">Zona Seleccionada</div>
          ) : (
            <div style={{ fontStyle: "italic", color: "#666" }}>
              Activa el interruptor para definir el área en el mapa.
            </div>
          )}

          {/* {mapResults !== undefined && window.innerWidth < 750 && (
            <button
              className="edit-map-button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setMapOpen(true);
              }}
            >
              Editar mapa
            </button>
          )} */}
        </div>

        <div className="postjob-gray-container mt-25 " id="custom-questions">
          <CustomQuestions setQuestions={setQuestions} questions={questions} />
        </div>
        <div className="postjob-gray-container mt-25 ">
          <div
            className="postjob-container-title"
            style={{ marginBottom: "5px" }}
          >
            Rango Salarial (Opcional)
          </div>
          <div
            className="postjob-container-subtitle"
            style={{ marginBottom: "25px", color: "rgba(0, 0, 0, 0.6)" }}
          >
            Ingresa el rango salarial mensual en USD (deja en blanco si no
            aplica)
          </div>
          <div className="w100 flx" style={{ gap: "20px" }}>
            <div className="w100" style={{ maxWidth: "200px" }}>
              <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                Minimo
              </div>

              <div className="search-pill currency-input-container">
                <span className="currency-symbol">$</span>
                <input
                  type="number"
                  value={salaryRange[0] || ""}
                  className="search-pill-input currency-input"
                  onChange={(e) => handleSalaryRange(e, 0)}
                />
              </div>
            </div>

            <div className="w100" style={{ maxWidth: "200px" }}>
              <div style={{ marginBottom: "10px", fontSize: "18px" }}>
                Máximo
              </div>
              <div className="search-pill currency-input-container">
                <span className="currency-symbol">$</span>
                <input
                  type="number"
                  value={salaryRange[1] || ""}
                  className="search-pill-input currency-input"
                  onChange={(e) => handleSalaryRange(e, 1)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="postjob-gray-container mt-25">
          <div className="postjob-container-title">Preguntas Adicionales</div>

          <TrueorFalse
            name={"Modalidad"}
            value={inPerson}
            setResult={setInPerson}
            optionA="Presencial"
            optionB="Remoto"
          />
          <TrueorFalse
            name={"Tipo de posición"}
            setResult={setFullTime}
            value={fullTime}
            optionA="Tiempo Completo"
            optionB="Medio Tiempo"
          />
        </div>

        <div className=" flx flx-center mt-25">
          <button
            type="submit"
            className="postjob-createjob-button"
            style={{ color: "white" }}
          >
            Validar
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateJob;
