import React, { createContext, useContext, useState, useCallback } from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import { getApplicantsByCompanyId } from "../../utils/companyFunctions";
import {
  getApplicantsById,
  handleApplicantState,
} from "../../utils/applicantFunctions";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";

interface ApplicantContextType {
  // Company-wide applicants
  companyApplicants: ApplicantInt[];
  companyLoading: boolean;
  hasMoreCompanyApplicants: boolean;
  selectedCompanyApplicant: ApplicantInt | null;
  setSelectedCompanyApplicant: (applicant: ApplicantInt | null) => void;

  // Job-specific applicants
  jobApplicants: ApplicantInt[];
  jobLoading: boolean;
  selectedJobApplicant: ApplicantInt | null;
  setSelectedJobApplicant: (applicant: ApplicantInt | null) => void;

  // Methods
  fetchCompanyApplicants: (
    companyId: string,
    page: number,
    limit: number,
    searchTerm: string,
    filters: { [key: string]: string }
  ) => Promise<void>;
  fetchJobApplicants: (jobId: string) => Promise<void>;
  updateApplicant: (
    updatedApplicant: ApplicantInt,
    source: "company" | "job"
  ) => void;
  clearJobApplicants: () => void;
  clearAll: () => void;

  // Add new method for state updates
  updateApplicantState: (
    applicant: ApplicantInt,
    newState: string,
    source: "company" | "job",
    stateOption?: applicantStateOption
  ) => Promise<void>;

  // Add new metadata field
  companyApplicantMetadata: {
    pagination: {
      total: number;
      currentPage: number;
      totalPages: number;
      hasMore: boolean;
    };
    metadata: {
      totalApplicants: number;
      seenApplicants: number;
      unseenApplicants: number;
      seenPercentage: number;
    };
  } | null;
}

const ApplicantContext = createContext<ApplicantContextType | undefined>(
  undefined
);

export function ApplicantProvider({ children }: { children: React.ReactNode }) {
  // Company-wide applicants state
  const [companyApplicants, setCompanyApplicants] = useState<ApplicantInt[]>(
    []
  );
  const [companyLoading, setCompanyLoading] = useState(false);
  const [hasMoreCompanyApplicants, setHasMoreCompanyApplicants] =
    useState(true);
  const [selectedCompanyApplicant, setSelectedCompanyApplicant] =
    useState<ApplicantInt | null>(null);

  // Job-specific applicants state
  const [jobApplicants, setJobApplicants] = useState<ApplicantInt[]>([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [selectedJobApplicant, setSelectedJobApplicant] =
    useState<ApplicantInt | null>(null);

  // Add new state for metadata
  const [companyApplicantMetadata, setCompanyApplicantMetadata] =
    useState<ApplicantContextType["companyApplicantMetadata"]>(null);

  const fetchCompanyApplicants = useCallback(
    async (
      companyId: string,
      page: number,
      limit: number,
      searchTerm: string,
      filters: { [key: string]: string }
    ) => {
      try {
        setCompanyLoading(true);
        const result = await getApplicantsByCompanyId(
          companyId,
          page,
          limit,
          searchTerm,
          filters
        );

        if (page === 1) {
          setCompanyApplicants(result.applicants);
        } else {
          setCompanyApplicants((prev) => [...prev, ...result.applicants]);
        }
        // Store metadata
        setCompanyApplicantMetadata({
          pagination: result.pagination,
          metadata: result.metadata,
        });
        setHasMoreCompanyApplicants(result.applicants.length === limit);
      } catch (error) {
        console.error("Error fetching company applicants:", error);
      } finally {
        setCompanyLoading(false);
      }
    },
    []
  );

  const fetchJobApplicants = useCallback(async (jobId: string) => {
    try {
      setJobLoading(true);
      const result = await getApplicantsById(jobId);
      if (result) {
        setJobApplicants(result);
        setSelectedJobApplicant(result[0]);
      }
    } catch (error) {
      console.error("Error fetching job applicants:", error);
    } finally {
      setJobLoading(false);
    }
  }, []);

  const updateApplicant = useCallback(
    (updatedApplicant: ApplicantInt, source: "company" | "job") => {
      // Update in the specified list
      if (source === "company") {
        setCompanyApplicants((prev) =>
          prev.map((applicant) =>
            applicant._id === updatedApplicant._id
              ? updatedApplicant
              : applicant
          )
        );
      } else if (source === "job") {
        setJobApplicants((prev) =>
          prev.map((applicant) =>
            applicant._id === updatedApplicant._id
              ? updatedApplicant
              : applicant
          )
        );
      }
    },
    []
  );

  const clearJobApplicants = useCallback(() => {
    setJobApplicants([]);
  }, []);

  const updateApplicantState = useCallback(
    async (
      applicant: ApplicantInt,
      newState: string,
      source: "company" | "job",
      stateOption?: applicantStateOption
    ) => {
      const updatedApplicant = { ...applicant, state: newState };
      if (stateOption) {
        updatedApplicant.stateOption = stateOption;
      }

      // Optimistically update the UI
      if (source === "company") {
        setCompanyApplicants((prev) =>
          prev.map((a) => (a._id === applicant._id ? updatedApplicant : a))
        );
      } else {
        setJobApplicants((prev) =>
          prev.map((a) => (a._id === applicant._id ? updatedApplicant : a))
        );
      }

      try {
        const result = await handleApplicantState(
          updatedApplicant,
          newState,
          stateOption
        );
        if (!result) {
          throw new Error("Failed to update applicant");
        }
      } catch (error) {
        console.error("Error updating applicant:", error);

        // Revert changes on error
        if (source === "company") {
          setCompanyApplicants((prev) =>
            prev.map((a) => (a._id === applicant._id ? applicant : a))
          );
        } else {
          setJobApplicants((prev) =>
            prev.map((a) => (a._id === applicant._id ? applicant : a))
          );
        }
      }
    },
    []
  );

  const clearAll = useCallback(() => {
    setCompanyApplicants([]);
    setJobApplicants([]);
    setSelectedCompanyApplicant(null);
    setSelectedJobApplicant(null);
    setHasMoreCompanyApplicants(true);
    setCompanyApplicantMetadata(null); // Clear metadata
  }, []);

  return (
    <ApplicantContext.Provider
      value={{
        companyApplicants,
        companyLoading,
        hasMoreCompanyApplicants,
        selectedCompanyApplicant,
        setSelectedCompanyApplicant,
        jobApplicants,
        jobLoading,
        selectedJobApplicant,
        setSelectedJobApplicant,
        fetchCompanyApplicants,
        fetchJobApplicants,
        updateApplicant,
        clearJobApplicants,
        updateApplicantState,
        clearAll,
        companyApplicantMetadata,
      }}
    >
      {children}
    </ApplicantContext.Provider>
  );
}

export function useApplicants() {
  const context = useContext(ApplicantContext);
  if (context === undefined) {
    throw new Error("useApplicants must be used within an ApplicantProvider");
  }
  return context;
}
