import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ApplicantInt } from "../../../typescript/interfaces/AppInterface";
import StateColors from "../../applicants-sidebar/StateColors";
import { applicantStateOption } from "../../../typescript/interfaces/JobInterface";
import { useApplicantState } from "../../routing/ApplicantStateContext";

interface ApplicantStateDropDownProps {
  applicant: ApplicantInt;
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: string,
    stateOption?: applicantStateOption
  ) => Promise<void>;
  stateOptions: applicantStateOption[];
  loading?: boolean;
}

function ApplicantStateDropDown({
  applicant,
  setApplicantsState,
  stateOptions,
  loading,
}: ApplicantStateDropDownProps) {
  const [open, setOpen] = useState(false);

  const { defaultOptions } = useApplicantState();

  const options = stateOptions?.length ? stateOptions : defaultOptions;

  return (
    <div className="asdd-container ">
      <div
        className={`asdd-box-header min-h-[49px] ${
          open ? "asdd-box-header-open" : ""
        }`}
        onClick={() => {
          if (!loading) {
            setOpen(!open);
          }
        }}
      >
        {loading ? (
          <div>...</div>
        ) : (
          <>
            <div>
              <StateColors
                state={
                  options.find((o) => o.id === applicant.state)?.name || ""
                }
                color={
                  options.find((o) => o.id === applicant.state)?.color || ""
                }
              />
            </div>
            <ArrowDropDownIcon
              style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          </>
        )}
      </div>

      <div className={`asdd-box ${!open && "hide"} z-50`}>
        {options.map((option, index) => {
          if (option.name !== applicant.state) {
            return (
              <div
                className="asdd-box-item"
                key={index}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setApplicantsState(e, applicant, option.id, option);

                  setOpen(false);
                }}
              >
                <StateColors state={option.name} color={option.color} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default ApplicantStateDropDown;
