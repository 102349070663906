// creates automation trigger based on state id and trigger

import axios from "axios";
import { AutomationTrigger } from "../../typescript/interfaces/AutomationTypes";

export const createAutomationTrigger = async (trigger: AutomationTrigger) => {
  try {
    const result = await axios.post(
      `https://createautomationtrigger-gi2cautoja-rj.a.run.app`,

      trigger
    );
    console.log(result);
    return result;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message);
  }
};
