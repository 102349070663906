import React from "react";
import { AutomationAction } from "../../../../typescript/interfaces/AutomationTypes";
import { applicantStateOption } from "../../../../typescript/interfaces/JobInterface";

interface MovementAutomationTemplateProps {
  action: AutomationAction;
  updateAction: (updates: Partial<AutomationAction>) => void;
  stateOptions: applicantStateOption[];
  selectedTriggerId: string;
}

function MovementAutomationTemplate({
  action,
  updateAction,
  stateOptions,
  selectedTriggerId,
}: MovementAutomationTemplateProps) {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Estado Destino
      </label>
      <select
        required
        value={action.config.targetStateId || ""}
        onChange={(e) =>
          updateAction({
            config: {
              ...action.config,
              targetStateId: e.target.value,
              targetStateOption: stateOptions.find(
                (option) => option.id === e.target.value
              ) as applicantStateOption,
            },
          })
        }
        className="w-full px-3 py-2 border border-gray-300 rounded-md"
      >
        <option value="">Seleccionar estado</option>
        {stateOptions.map(
          (option) =>
            option.id !== selectedTriggerId && (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            )
        )}
      </select>
    </div>
  );
}

export default MovementAutomationTemplate;
