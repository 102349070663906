import React from "react";
import { Typography, Button, Paper } from "@mui/material";
import { CompanyInt } from "../../../typescript/interfaces/CompanyInt";
import { JobInt } from "../../../typescript/interfaces/JobInterface";

interface CompanyPageLayoutProps {
  company: CompanyInt;
  pageData: {
    description: string;
    bannerUrl: string;
    logoUrl: string;
    name: string;
  };
  positions?: JobInt[];
}

const EmptyStateMessage = ({ message }: { message: string }) => (
  <Typography className="text-gray-400 italic">{message}</Typography>
);

const CompanyPageLayout: React.FC<CompanyPageLayoutProps> = ({
  company,
  pageData,
  positions = [],
}) => {
  return (
    <div className="w-full min-h-screen">
      {/* Banner and Logo Section */}
      <div>
        <div className="relative">
          {pageData.bannerUrl ? (
            <div className="h-[70vh] w-full">
              <img
                src={pageData.bannerUrl}
                alt="Company banner"
                className="w-full h-full object-cover"
              />
            </div>
          ) : (
            <div className="h-[40vh] w-full bg-gray-100 flex items-center justify-center">
              <EmptyStateMessage message="No se ha subido imagen de banner" />
            </div>
          )}

          {/* Logo - Positioned over banner */}
          <div className="absolute -bottom-12 left-[5%]">
            <div className="w-28 h-28 md:w-32 md:h-32 rounded-full bg-white p-1 shadow-lg">
              <div className="w-full h-full rounded-full overflow-hidden">
                {pageData.logoUrl ? (
                  <img
                    src={pageData.logoUrl}
                    alt="Company logo"
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <div className="h-full w-full bg-gray-100 flex items-center justify-center">
                    <EmptyStateMessage message="Logo" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wrap content in a container with responsive padding */}
      <div className="px-4 md:px-8 lg:px-16 xl:px-24">
        {/* Adjust margin-top to account for larger logo */}
        <div className="mt-16">
          <Typography variant="h4" className="font-medium text-gray-800">
            {pageData.name}
          </Typography>
        </div>

        {/* About Section */}
        <section className="mt-8">
          <Typography variant="h6" className="text-gray-700 mb-3">
            Sobre Nosotros
          </Typography>
          {pageData.description ? (
            <Typography className="text-gray-600 max-w-[800px]">
              {pageData.description}
            </Typography>
          ) : (
            <EmptyStateMessage message="Añade una descripción de tu empresa" />
          )}
        </section>
      </div>

      {/* Job Positions Section - Now full width */}
      <section className="bg-gray-50 p-4 md:p-8 mt-8 mb-8 pb-8 md:pb-12 lg:pb-16 xl:pb-20">
        <div className="max-w-[1500px] mx-auto">
          <div className="max-w-[2000px] mx-auto px-4 md:px-8 lg:px-16 xl:px-24">
            <div className="text-center mb-8">
              <Typography
                variant="h5"
                className="font-medium text-gray-800 mb-2"
              >
                Vacantes Disponibles en {pageData.name}
              </Typography>
              <Typography variant="body1" className="text-gray-600">
                Explora nuestras oportunidades laborales actuales
              </Typography>
            </div>
          </div>
          {positions.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-6 justify-items-center">
              {positions.map((job, index) => (
                <Paper
                  key={index}
                  className="w-full min-w-[200px] max-w-[320px] h-[130px] p-4 rounded-2xl border border-gray-100 hover:shadow-xl transition-all duration-300 bg-white flex flex-col justify-between !shadow-[0_2px_8px_-1px_rgba(0,0,0,0.05)] hover:!shadow-[0_8px_20px_-4px_rgba(0,0,0,0.1)]"
                  elevation={0}
                >
                  <div>
                    <div className="flex justify-between items-start">
                      <Typography className="!text-md !text-black font-medium mb-2 ">
                        {job.title.length > 30
                          ? `${job.title.substring(0, 30)}...`
                          : job.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        className="bg-laburo-green text-white !text-xs px-2 py-1 rounded-sm "
                      >
                        {job.inPerson ? "En persona" : "Remoto"}
                      </Typography>
                    </div>

                    <Typography variant="body2" className="text-gray-500 mb-4">
                      {job.location.city}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      href={`https://quierolaburo.com/job-des/job-apply/?id=${job._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:bg-laburo-green/90 transition-all duration-300 "
                      style={{
                        padding: "3px",
                        fontSize: "12px",
                        marginTop: "10px",
                        backgroundColor: "transparent",
                        textAlign: "left",
                        color: "var(--laburo-green)",
                        boxShadow: "none",
                      }}
                    >
                      Ver y Aplicar
                    </Button>
                  </div>
                </Paper>
              ))}
            </div>
          ) : (
            <EmptyStateMessage message="No hay posiciones abiertas actualmente" />
          )}
        </div>
      </section>

      {/* Powered by Laburo Footer */}
      <div className="text-center py-6 bg-white">
        <Typography
          variant="body2"
          className="text-gray-500 flex items-center justify-center gap-1"
        >
          Powered by
          <a
            href="https://www.quierolaburo.com/empresas"
            className="text-laburo-green font-medium "
            target="_blank"
            rel="noopener noreferrer"
          >
            Laburo
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default CompanyPageLayout;
