import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import FilterDropdown from "./tools/FilterDropdown";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";
import { useApplicantState } from "../routing/ApplicantStateContext";
import FilterDropdownWithId from "./tools/FilterDropDownWithId";

interface AppllicantFilterPopUpProps {
  name: string;
  setPopUpOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleLocationFilterChange: (
    filter: "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  ) => void;
  showLocationFilter: boolean;
  locationFilterChange:
    | "Todos los aplicantes"
    | "Dentro de la zona"
    | "Afuera de la zona";
}

function ApplicantFilterPopUp(props: AppllicantFilterPopUpProps) {
  const { currentState, stateOptions, setCurrentState } = useApplicantState();
  const [selectedLocationFilter, setSelectedLocationFitler] = useState<
    "Todos los aplicantes" | "Dentro de la zona" | "Afuera de la zona"
  >(props.locationFilterChange);
  const [applicantsStateFilter, setApplicantsStateFilter] =
    useState<applicantStateOption>(currentState);
  const handleSubmit = (
    selection:
      | "Todos los aplicantes"
      | "Dentro de la zona"
      | "Afuera de la zona"
  ) => {
    props.handleLocationFilterChange(selection);
    // TODO: update the state options for filter
    // props.handleStateFilterChange(applicantsStateFilter);
    setCurrentState(applicantsStateFilter);
    props.setPopUpOpen(false);
  };
  return (
    <div className="dropdown-popup-container filter-popup-container ">
      <div
        className="dropdown-popup-content"
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <div className="w100">
          <div
            className="flx flx-center"
            style={{ fontSize: "22px", position: "relative" }}
          >
            {props.name}{" "}
            <div
              className="flx flx-center"
              style={{ position: "absolute", right: 5, top: 0 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.setPopUpOpen(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="dropdown-popup-divider"></div>
          <div className="dropdown-popup-items" style={{ padding: "0px" }}>
            {props.showLocationFilter && (
              <div>
                <div style={{ marginBottom: "15px" }}>Filtro De Proximidad</div>
                <FilterDropdown
                  placeholder="Candidatos"
                  highlightOptions={false}
                  state={selectedLocationFilter}
                  setState={
                    setSelectedLocationFitler as React.Dispatch<
                      React.SetStateAction<string>
                    >
                  }
                  options={[
                    "Todos los aplicantes",
                    "Dentro de la zona",
                    "Afuera de la zona",
                  ]}
                />
              </div>
            )}

            <div>
              <div style={{ marginBottom: "15px" }}>Filtro De Estado</div>
              <FilterDropdownWithId
                options={stateOptions}
                value={applicantsStateFilter}
                highlightOptions={true}
                onChange={(option) => {
                  setApplicantsStateFilter(option as applicantStateOption);
                }}
                defaultOption={{
                  id: "Todos",
                  name: "Todos",
                  color: "rgba(0,0,0,0.4)",
                  order: 0,
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="w100"
          onClick={() => {
            handleSubmit(selectedLocationFilter ?? "Todos los aplicantes");
          }}
        >
          <button className="dropdown-popup-button">Guardar</button>
        </div>
      </div>
    </div>
  );
}

export default ApplicantFilterPopUp;
