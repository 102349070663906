import axios from "axios";
import { EmailTemplate } from "../../typescript/interfaces/EmailType";

export const upsertEmailTemplate = async (template: EmailTemplate) => {
  try {
    const response = await axios.post(
      `https://createemailtemplate-gi2cautoja-rj.a.run.app`,
      template
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error upserting email template:", error);
    throw error;
  }
};
