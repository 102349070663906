import axios from "axios";

export const deleteAutomationTrigger = async (
  triggerId: string,
  jobId: string,
  stateId: string
) => {
  try {
    console.log("deleting trigger", triggerId, jobId, stateId);
    const result = axios.delete(
      `https://deleteautomationtrigger-gi2cautoja-rj.a.run.app?automationTriggerId=${triggerId}&jobId=${jobId}&stateId=${stateId}`
    );

    return result;
  } catch (error) {
    console.error(error);
  }
};
