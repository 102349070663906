import React, { useState, useEffect, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StateColors from "../../applicants-sidebar/StateColors";

interface DropdownOption {
  id: string | number;
  name: string;
  color?: string;
  order?: number;
  [key: string]: any; // Allows for additional fields
}

interface FilterDropdownWithIdProps {
  options: DropdownOption[];
  value: DropdownOption | null;
  onChange: (option: DropdownOption) => void;
  placeholder?: string;
  highlightOptions?: boolean;
  defaultOption?: DropdownOption;
}

function FilterDropdownWithId({
  options,
  value,
  onChange,
  placeholder = "Estado",
  highlightOptions,
  defaultOption,
}: FilterDropdownWithIdProps) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add default option as first item if it exists
  const sortedOptions = defaultOption
    ? [defaultOption, ...options.filter((opt) => opt.id !== defaultOption.id)]
    : options;

  return (
    <div className="filter-dropdown-container" ref={dropdownRef}>
      <div className="filter-dropdown">
        <div
          className={`asdd-box-header filter-dropdown-header ${
            open ? "asdd-box-header-open" : ""
          }`}
          onClick={() => setOpen(!open)}
        >
          <div className="flx" style={{ alignItems: "baseline" }}>
            <span
              style={{
                color: "rgba(0,0,0,0.6)",
                marginRight: "5px",
                fontSize: "16px",
              }}
            >
              {placeholder}:
            </span>
            {highlightOptions && value?.color ? (
              <StateColors state={value.name} color={value.color} />
            ) : (
              <span style={{ fontSize: "16px" }}>{value?.name || ""}</span>
            )}
          </div>
          <ArrowDropDownIcon
            style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </div>

        <div className={`filter-dropdown-box ${!open && "hide"}`}>
          {sortedOptions.map((option) => (
            <div
              className="asdd-box-item"
              key={option.id}
              onClick={() => {
                onChange(option);
                setOpen(false);
              }}
            >
              {highlightOptions && option.color ? (
                <StateColors state={option.name} color={option.color} />
              ) : (
                <span
                  style={{
                    padding: "5px 12px",
                    fontSize: "16px",
                  }}
                >
                  {option.name}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterDropdownWithId;
