import axios from "axios";
import { AutomationTrigger } from "../../typescript/interfaces/AutomationTypes";

export const updateAutomationTrigger = async (data: AutomationTrigger) => {
  try {
    const result = await axios.patch(
      `https://updateautomationtrigger-gi2cautoja-rj.a.run.app`,

      data
    );
    console.log("result", result);
    return result;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response.data.message);
  }
};
