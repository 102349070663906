import React, { useState } from "react";
import { applicantStateOption } from "../../typescript/interfaces/JobInterface";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface ApplicantOptionSelectorProps {
  setSelectedApplicantOptions: React.Dispatch<
    React.SetStateAction<applicantStateOption[]>
  >;
  selectedApplicantOptions: applicantStateOption[];
  backgroundColor?: string;
}

function ApplicantOptionSelector(props: ApplicantOptionSelectorProps) {
  const [newStageName, setNewStageName] = useState("");
  const [draggedItem, setDraggedItem] = useState<number | null>(null);

  const stages = props.selectedApplicantOptions;

  const handleDragStart = (index: number) => {
    setDraggedItem(index);
  };

  const handleDragOver = (e: React.DragEvent, index: number) => {
    e.preventDefault();
    if (draggedItem === null) return;

    const newStages = [...stages];
    const draggedStage = newStages[draggedItem];
    newStages.splice(draggedItem, 1);
    newStages.splice(index, 0, draggedStage);

    // Update order numbers
    newStages.forEach((stage, idx) => {
      stage.order = idx;
    });

    props.setSelectedApplicantOptions(newStages);
    setDraggedItem(index);
  };

  const handleColorChange = (color: string, stageId: string) => {
    const updatedStages = stages.map((stage) =>
      stage.id === stageId ? { ...stage, color } : stage
    );
    props.setSelectedApplicantOptions(updatedStages);
  };

  const addNewStage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    if (!newStageName.trim()) return;

    const newStage: applicantStateOption = {
      id: newStageName + Date.now().toString(),
      name: newStageName,
      color: "#718096",
      order: stages.length,
      canDelete: true,
    };

    props.setSelectedApplicantOptions([...stages, newStage]);

    setNewStageName("");
  };

  const deleteStage = (stageId: string) => {
    const filteredStages = stages.filter((stage) => stage.id !== stageId);
    // Update order numbers after deletion
    filteredStages.forEach((stage, idx) => {
      stage.order = idx;
    });
    props.setSelectedApplicantOptions(filteredStages);
  };

  return (
    <div
      className="postjob-gray-container  p-6 shadow-sm"
      style={{
        backgroundColor: props.backgroundColor,
        marginTop: props.backgroundColor ? "0px" : "25px",
      }}
    >
      <h2
        className="text-2xl font-semibold mb-4"
        style={{
          fontSize: props.backgroundColor ? "18px" : "",
          fontWeight: props.backgroundColor ? "normal" : "",
        }}
      >
        Etapas
      </h2>

      {/* Add new stage */}
      <div className="flex gap-2 mb-6">
        <input
          type="text"
          value={newStageName}
          onChange={(e) => setNewStageName(e.target.value)}
          placeholder="Nombre de la Etapa (Ej: Prefiltro,  Evaluación Técnica, Oferta)"
          className="px-3 py-2 border rounded-md flex-1"
        />
        <button
          onClick={(e) => {
            addNewStage(e);
          }}
          className="bg-laburo-green text-white px-4 py-2 rounded-md hover:laburo-green transition-colors"
        >
          Añadir {!props.backgroundColor && "Etapa"}
        </button>
      </div>

      {/* Stages list */}
      <div className="space-y-2">
        {stages.map((stage, index) => (
          <div
            key={stage.id}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            className="flex items-center gap-4 bg-white p-4 rounded-md shadow-sm cursor-move hover:shadow-md transition-shadow"
          >
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={stage.color}
                onChange={(e) => handleColorChange(e.target.value, stage.id)}
                className="w-6 h-6 rounded cursor-pointer "
                style={{
                  padding: 5,
                  border: "none",
                  appearance: "none",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                }}
              />
              <span className="font-medium">{stage.name}</span>
            </div>

            <div className="flex items-center gap-2 ml-auto">
              {stage.canDelete && (
                <button
                  onClick={() => deleteStage(stage.id)}
                  className="text-red-500 hover:text-red-700 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <DeleteOutlineIcon />
                  </svg>
                </button>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ApplicantOptionSelector;
