import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getPostedJobsById } from "../../utils/applicantFunctions";
import { JobInt } from "../../typescript/interfaces/JobInterface";
import { useUser } from "../../components/routing/UserContext";
interface JobPositionsContextType {
  positions: JobInt[] | undefined;
  isLoading: boolean;
  refreshPositions: () => Promise<void>;
  clearPositions: () => void;
  paginationData: {
    page: number;
    limit: number;
    totalPages: number;
    totalPositions: number;
  };
  fetchPositions: (
    filters: { [key: string]: string },
    page: number,
    limit: number
  ) => Promise<void>;
  setPositions: React.Dispatch<React.SetStateAction<JobInt[] | undefined>>;
}

const JobPositionsContext = createContext<JobPositionsContextType | undefined>(
  undefined
);

export function JobPositionsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [positions, setPositions] = useState<JobInt[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const [authReady, setAuthReady] = useState(false);
  const auth = getAuth();
  const { userData } = useUser();
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    totalPositions: 0,
  });

  const fetchPositions = useCallback(
    async (
      filters: { [key: string]: string } = {},
      page: number = 1,
      limit: number = 10
    ) => {
      if (auth.currentUser?.email) {
        try {
          setIsLoading(true);
          const fetchedPositions = await getPostedJobsById(
            auth.currentUser.email,
            filters,
            limit,
            page,
            userData?.companyId
          );
          setPositions(fetchedPositions.jobs);
          setPaginationData({
            page: fetchedPositions.pagination.page,
            limit: fetchedPositions.pagination.limit,
            totalPages: fetchedPositions.pagination.totalPages,
            totalPositions: fetchedPositions.pagination.total,
          });
        } catch (error) {
          console.error("Error fetching positions:", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [auth.currentUser?.email, userData?.companyId]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthReady(true);
      if (user?.email) {
        fetchPositions();
      }
    });
    return () => unsubscribe();
  }, [auth, fetchPositions]);
  useEffect(() => {
    if (authReady && auth.currentUser?.email) {
      fetchPositions();
    }
  }, [fetchPositions, authReady, auth.currentUser?.email]);

  useEffect(() => {}, [auth.currentUser?.email]);

  const clearPositions = useCallback(() => {
    setPositions(undefined);
    setPaginationData({
      page: 1,
      limit: 10,
      totalPages: 1,
      totalPositions: 0,
    });
  }, []);

  return (
    <JobPositionsContext.Provider
      value={{
        positions,
        isLoading,
        refreshPositions: () => fetchPositions(),
        clearPositions,
        fetchPositions,
        setPositions,
        paginationData,
      }}
    >
      {children}
    </JobPositionsContext.Provider>
  );
}

export function useJobPositions() {
  const context = useContext(JobPositionsContext);
  if (context === undefined) {
    throw new Error(
      "useJobPositions must be used within a JobPositionsProvider"
    );
  }
  return context;
}
