import "firebase/firestore";

import axios from "axios";
import { JobInt } from "../typescript/interfaces/JobInterface";

interface LocationData {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
}
// ----------refactor ---------

const getJobs = async (
  location?: LocationData,
  position?: string,
  lastIndex?: string,
  lastDatePosted?: string,
  filters?: {},
  pageSize?: number,
  setLength?: React.Dispatch<React.SetStateAction<number>>
) => {
  // return array of jobs with last one with id

  let filteredObject;

  if (filters) {
    filteredObject = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== undefined)
    );
  }

  //

  const reqData = {
    location: location || {
      city: "",
      country: "",
      latitude: 0,
      longitude: 0,
    },
    title: position,
    pageSize: pageSize,
    lastId: lastIndex,
    lastDatePosted: lastDatePosted,
    filters: filteredObject || {},
  };
  const result = await axios.post(
    "https://getjobs-gi2cautoja-rj.a.run.app",
    reqData
  );

  if (setLength) {
    setLength(result.data.length);
  }
  return result.data.results;
};

const getDbLength = async (location?: LocationData, position?: string | "") => {
  const reqData = {
    location: location || {
      city: "",
      country: "",
      latitude: 0,
      longitude: 0,
    },
    title: position,
  };
  const result = await axios.post(
    "https://getdblength-gi2cautoja-rj.a.run.app",
    reqData
  );
  return result.data.results;
};

const getJobById = async (jobId: string) => {
  const data = {
    id: jobId,
  };
  try {
    const result = await axios.post(
      "https://findjob-gi2cautoja-rj.a.run.app",
      data
    );

    return result.data.results;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
};

const updateDbStatusDescription = async (jobData: JobInt, withID?: boolean) => {
  try {
    if (withID) {
      await axios.post(
        "https://updatejobpostbyid-gi2cautoja-rj.a.run.app",
        jobData
      );
      console.log("updating with _id");
    } else {
      await axios.post(
        "https://editjobstatusanddescription-gi2cautoja-rj.a.run.app",
        jobData
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const getJobByEditKey = async (jobId: string) => {
  try {
    const result = await axios.post(
      "https://findjobbyeditkey-gi2cautoja-rj.a.run.app",
      { editKey: jobId }
    );

    return result.data.results;
  } catch (error) {
    console.log(error);
  }
};

const generateJobPostingStructuredData = (job: JobInt) => {
  const structuredData: any = {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    title: job.title,
    description: job.description,
    datePosted: job.datePosted,
    validThrough: new Date(
      new Date(job.datePosted).getTime() + 45 * 24 * 60 * 60 * 1000
    ).toISOString(),
    hiringOrganization: {
      "@type": "Organization",
      name: job.company,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: job.location.city,
        addressCountry: job.location.country,
      },
    },
    employmentType: job.fullTime ? "FULL_TIME" : "PART_TIME",
  };

  return structuredData;
};

const deleteJobById = async (jobId: string, companyId: string) => {
  try {
    const response = await axios.post(
      "https://removejob-gi2cautoja-rj.a.run.app",
      {
        id: jobId,
        companyId,
      }
    );
    // await new Promise((resolve) => setTimeout(resolve, 5000));
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
// use places api to store place and coordinates
// for retrieval use autocomplete and also get coordinates so that then we can query db using geojson

export {
  getJobs,
  getDbLength,
  getJobById,
  getJobByEditKey,
  updateDbStatusDescription,
  generateJobPostingStructuredData,
  deleteJobById,
};
