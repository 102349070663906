import axios from "axios";
import CompanyPageInt from "../typescript/interfaces/CompanyPageInt";

const createCompanyPage = async (data: Partial<CompanyPageInt>) => {
  try {
    const response = await axios.post(
      `https://createcompanypage-gi2cautoja-rj.a.run.app`,
      data
    );
    return response.data.companyPage;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Handle specific HTTP error codes
      if (error.response.status === 409) {
        throw new Error("Ya existe una página de empresa con este subdominio");
      }
      // Handle other specific error messages from the backend
      if (error.response.data?.message) {
        throw new Error(error.response.data.message);
      }
    }
    // Fallback error
    console.error("Error al crear la página de empresa:", error);
    throw new Error("No se pudo crear la página de empresa");
  }
};

const getCompanyPage = async (subdomainId?: string, companyId?: string) => {
  try {
    // Create URL parameters only for defined values
    const params = new URLSearchParams();
    if (subdomainId) params.append("subdomainId", subdomainId);
    if (companyId) params.append("companyId", companyId);

    const response = await axios.get(
      `https://getcompanypage-gi2cautoja-rj.a.run.app?${params.toString()}`
    );

    return response.data.companyPage;
  } catch (error) {
    console.error("Error getting company page:", error);
    throw error;
  }
};

const updateCompanyPage = async (companyId: string, updates: any) => {
  try {
    const response = await axios.put(
      `https://updatecompanypage-gi2cautoja-rj.a.run.app`,
      { companyId, updates }
    );
    return response.data.companyPage;
  } catch (error) {
    console.error("Error updating company page:", error);
    throw error;
  }
};

const uploadCompanyPageImageToFirebase = async (
  file: File,
  companyId: string,
  type: "logo" | "banner"
) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("companyId", companyId);
    formData.append("imageType", type);

    const response = await axios.post(
      `https://uploadimagetofirebase-gi2cautoja-rj.a.run.app`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return {
      path: response.data.path,
      success: true,
    };
  } catch (error) {
    console.error("Error uploading image to firebase:", error);
    return {
      path: null,
      success: false,
    };
  }
};

export {
  createCompanyPage,
  getCompanyPage,
  updateCompanyPage,
  uploadCompanyPageImageToFirebase,
};
