import React, { useState } from "react";

import { JobInt } from "../typescript/interfaces/JobInterface";
import JobPost from "./JobPost";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StripeButton from "./stripe/StripeButton";
import LoadingWidget from "./widgets/LoadingWidget";
import { Link } from "react-router-dom";

import PostJobSignedIn from "./post-job/PostJobSignedIn";
import { Question } from "../typescript/interfaces/CustomQuestionInt";
import ValidateMap from "./create-job/ValidateMap";
import TextEditorVisualizer from "./text-editor/TextEditorVisualizer";
import { BusinessUserInt } from "../typescript/interfaces/BusinessUserInt";
import { CompanyInt } from "../typescript/interfaces/CompanyInt";

interface ValidateJobProps {
  job: JobInt;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  userData: BusinessUserInt | null;
  companyData: CompanyInt | null;
}

// protect this route since it should only be accessed by the company
// toDO: refactor so it deducts from the company's maxPostsAllowed and then refactor from the backend. stripe and all that
function ValidateJob(props: ValidateJobProps) {
  const [loading, setLoading] = useState(false);
  const postsAvailable =
    (props.companyData?.maxPostsAllowed as number) -
    (props.companyData?.currentPosts as number);
  //  if the user is signed in and has credits then show Publicar instead of Pagar
  //  change Pagar to Publicar also in the steps
  const decreaseStep = () => {
    props.setStep(1);
  };

  return (
    <div className="w100">
      <div>
        <div
          className="flx button-hover"
          onClick={decreaseStep}
          style={{
            marginBottom: "10px",
            alignItems: "center",
            width: "fit-content",
            fontSize: "18px",
          }}
        >
          <ArrowBackIcon /> Regresar
        </div>
      </div>

      <div
        className="validate-job-title flx-center flx"
        style={{ fontSize: "26px" }}
      >
        Validar Información
      </div>
      <div className="flx-col" style={{ gap: "20px", marginTop: "20px" }}>
        {/* imagen */}

        <div className="w100 flx flx-col" style={{ gap: "20px" }}>
          <div className="postjob-gray-container ">
            <div className="validate-job-title">Información Básica</div>
            <div
              className="w100 flx flx-col"
              style={{ gap: "10px", padding: "0px 10px" }}
            >
              <div className="validate-job-text">
                <span className="validate-job-label">Posición:</span>{" "}
                {props.job.title}
              </div>
              <div className="validate-job-text">
                <span className="validate-job-label">Empresa:</span>{" "}
                {props.job.company}
              </div>
              <div className="validate-job-text">
                {props.job.location.city && props.job.location.country ? (
                  <React.Fragment>
                    <span className="validate-job-label">Ubicación:</span>{" "}
                    {props.job.location.city +
                      ", " +
                      props.job.location.country}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="postjob-gray-container ">
            <div className="validate-job-text">
              {props.job.recieveViaEmail ? (
                <React.Fragment>
                  <span className="validate-job-title">Descripción:</span>
                  <TextEditorVisualizer htmlContent={props.job.description} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="validate-job-label">Nota:</span> Los
                  aplicantes seran redireccionados a {props.job.recieveEmail}
                </React.Fragment>
              )}
            </div>
          </div>
          {props.job.mapResults && (
            <div className="postjob-gray-container ">
              <div className="validate-job-text">
                <React.Fragment>
                  <span className="validate-job-title">
                    Filtro de Ubicación
                  </span>
                  <div
                    className="validate-job-text "
                    style={{
                      maxHeight: "none",
                      padding: "10px 10px",
                    }}
                  >
                    <ValidateMap mapResults={props.job.mapResults} />
                  </div>
                </React.Fragment>
              </div>
            </div>
          )}
          {props.job.questions && props.job.questions.length > 0 && (
            <div className="postjob-gray-container ">
              <div className="validate-job-text ">
                <span className="validate-job-title">
                  Preguntas Personalizadas:
                </span>
                <div
                  className="w100 "
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {props.job.questions.map(
                    (question: Question, index: number) => (
                      <div key={index} className="validate-job-custom-question">
                        <p>
                          <span
                            className=""
                            style={{
                              textDecoration: "underline",
                              marginRight: "10px",
                            }}
                          >
                            Pregunta {index + 1}:
                          </span>{" "}
                          {question.question}
                        </p>
                        <p>
                          <span className="">Tipo:</span>{" "}
                          {question.type === "text"
                            ? "Texto"
                            : "Opción múltiple"}
                        </p>
                        {question.type === "multiple" && question.options && (
                          <div>
                            <span className="">Opciones:</span>
                            <ul>
                              {question.options.map(
                                (option: string, optionIndex: number) => (
                                  <li key={optionIndex}>{option}</li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          {props.job.applicantStateOptions.length > 0 && (
            <div className="postjob-gray-container">
              <div className="validate-job-text">
                <span className="validate-job-title">Etapas del Proceso:</span>
                <div className="flex flex-col gap-2 mt-3">
                  {props.job.applicantStateOptions.map((stage) => (
                    <div
                      key={stage.id}
                      className="flex items-center gap-2 p-2 rounded-md"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        className="w-4 h-4 rounded-full"
                        style={{ backgroundColor: stage.color }}
                      />
                      <span>{stage.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Add custom questions display */}

        <div>
          <JobPost currJob={props.job} />{" "}
        </div>
      </div>

      <div className=" flx-col flx-center ">
        <div className="w100 flx flx-center mb-25">
          {loading ? (
            <LoadingWidget loading={loading} />
          ) : postsAvailable > 0 ? (
            <PostJobSignedIn
              job={props.job}
              setStep={props.setStep}
              businessUser={props.userData as BusinessUserInt}
            />
          ) : (
            <StripeButton body={props.job} setLoading={setLoading} />
          )}
        </div>

        {postsAvailable <= 0 && (
          <div
            className="laburo-green button-hover "
            style={{ width: "100%", textAlign: "center" }}
          >
            <Link to="/contact-us">
              Contáctanos por paquetes o para pagar con transferencia.
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ValidateJob;
