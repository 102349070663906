import React, { useState } from "react";
import {
  AutomationAction,
  AutomationConditionType,
} from "../../../../typescript/interfaces/AutomationTypes";
import { JobInt } from "../../../../typescript/interfaces/JobInterface";
import { Question } from "../../../../typescript/interfaces/CustomQuestionInt";

interface ConditionSectionProps {
  action: AutomationAction;
  index: number;
  updateAction: (updates: Partial<AutomationAction>) => void;
  selectedJob: JobInt;
}

const ConditionSection: React.FC<ConditionSectionProps> = ({
  action,
  index,
  updateAction,
  selectedJob,
}) => {
  // Add state to track unsaved input
  const [unsavedInput, setUnsavedInput] = useState("");

  return (
    <div className="border-t border-gray-200 pt-4">
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          id={`condition-toggle-${index}`}
          checked={!!action.condition}
          onChange={(e) => {
            updateAction({
              condition: e.target.checked
                ? {
                    type: "PROPERTY_INCLUDES",
                    config: {},
                  }
                : undefined,
            });
          }}
          className="rounded border-gray-300"
        />
        <label
          htmlFor={`condition-toggle-${index}`}
          className="text-sm font-medium text-gray-700"
        >
          Agregar Condición
        </label>
      </div>

      {action.condition && (
        <div className="space-y-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Tipo de Condición
            </label>
            <select
              value={action.condition.type}
              onChange={(e) => {
                // Get the first multiple choice question and its first option as defaults
                const firstMultipleQuestion = selectedJob.questions.find(
                  (question) => question.type === "multiple"
                );
                const defaultAnswer = firstMultipleQuestion?.options?.[0] || "";

                updateAction({
                  condition: {
                    ...action.condition!,
                    type: e.target.value as AutomationConditionType,
                    config:
                      e.target.value === "ANSWER_EQUALS"
                        ? {
                            questionId: firstMultipleQuestion?.question || "",
                            expectedAnswer: defaultAnswer,
                          }
                        : {},
                  },
                });
              }}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="PROPERTY_INCLUDES">
                Filtro Inteligente Vicky
              </option>
              {selectedJob.questions.length > 0 &&
                selectedJob.questions.find(
                  (question) => question.type === "multiple"
                ) && (
                  <option value="ANSWER_EQUALS">Respuesta del Candidato</option>
                )}
            </select>
          </div>

          {/* property includes */}
          {/* it will be an array of keywords that must be in the applicants application (cv or description) */}

          {action.condition.type === "PROPERTY_INCLUDES" && (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Ingresa palabras clave que buscas y Vicky filtrara los
                  candidatos por tí
                </label>
                <div className="flex flex-wrap gap-2 p-2 border border-gray-300 rounded-md">
                  {/* Display existing tags */}
                  {(action.condition.config.includesValue || []).map(
                    (tag: string, tagIndex: number) => (
                      <span
                        key={tagIndex}
                        className="bg-emerald-100 text-emerald-700 px-2 py-1 rounded-md flex items-center text-sm border border-emerald-300"
                      >
                        {tag.trim()}
                        <button
                          type="button"
                          onClick={() => {
                            const newTags = (
                              action.condition?.config.includesValue || []
                            ).filter((_: string, i: number) => i !== tagIndex);
                            updateAction({
                              condition: {
                                ...action.condition!,
                                config: {
                                  ...action.condition?.config,
                                  includesValue: newTags,
                                },
                              },
                            });
                          }}
                          className="ml-2 text-emerald-600 hover:text-emerald-800"
                        >
                          ×
                        </button>
                      </span>
                    )
                  )}
                  {/* Input for new tags */}
                  <input
                    type="text"
                    value={unsavedInput}
                    onChange={(e) => setUnsavedInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === ",") {
                        e.preventDefault();
                        const newTag = unsavedInput.trim();
                        if (
                          newTag &&
                          !action.condition?.config.includesValue?.includes(
                            newTag
                          )
                        ) {
                          const currentTags =
                            action.condition?.config.includesValue || [];
                          updateAction({
                            condition: {
                              ...action.condition!,
                              config: {
                                ...action.condition?.config,
                                includesValue: [...currentTags, newTag],
                              },
                            },
                          });
                          setUnsavedInput("");
                        }
                      } else if (e.key === "Backspace" && unsavedInput === "") {
                        // Remove last tag when backspace is pressed on empty input
                        e.preventDefault();
                        const currentTags =
                          action.condition?.config.includesValue || [];
                        if (currentTags.length > 0) {
                          updateAction({
                            condition: {
                              ...action.condition!,
                              config: {
                                ...action.condition?.config,
                                includesValue: currentTags.slice(0, -1),
                              },
                            },
                          });
                        }
                      }
                    }}
                    onBlur={() => {
                      const newTag = unsavedInput.trim();
                      if (
                        newTag &&
                        !action.condition?.config.includesValue?.includes(
                          newTag
                        )
                      ) {
                        const currentTags =
                          action.condition?.config.includesValue || [];
                        updateAction({
                          condition: {
                            ...action.condition!,
                            config: {
                              ...action.condition?.config,
                              includesValue: [...currentTags, newTag],
                            },
                          },
                        });
                      }
                      setUnsavedInput("");
                    }}
                    className="outline-none border-none flex-grow min-w-[120px] bg-gray-50 text-sm min-h-[30px]"
                    placeholder="Presiona Enter o coma para agregar"
                  />
                </div>
              </div>
            </>
          )}

          {action.condition.type === "ANSWER_EQUALS" && (
            <>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Selecciona la pregunta por la que quieres filtrar
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={action.condition.config.questionId || ""}
                  onChange={(e) =>
                    updateAction({
                      condition: {
                        ...action.condition!,
                        config: {
                          ...action?.condition?.config,
                          questionId: e.target.value,
                        },
                      },
                    })
                  }
                >
                  {selectedJob.questions.map(
                    (question: Question, index) =>
                      question.type === "multiple" && (
                        <option value={question.question} key={index}>
                          {question.question}
                        </option>
                      )
                  )}
                </select>
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Respuesta Esperada
                </label>
                {/* <input
                  type="text"
                  value={action.condition.config.expectedAnswer || ""}
                  onChange={(e) =>
                    updateAction({
                      condition: {
                        ...action.condition!,
                        config: {
                          ...action?.condition?.config,
                          expectedAnswer: e.target.value,
                        },
                      },
                    })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Respuesta esperada"
                /> */}
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={action.condition.config.expectedAnswer || ""}
                  onChange={(e) =>
                    updateAction({
                      condition: {
                        ...action.condition!,
                        config: {
                          ...action?.condition?.config,
                          expectedAnswer: e.target.value,
                        },
                      },
                    })
                  }
                >
                  {selectedJob.questions
                    .find((question) => question.type === "multiple")
                    ?.options?.map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                </select>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ConditionSection;
