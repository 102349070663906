import React, { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useApplicants } from "../routing/ApplicantContext";
import { useUser } from "../routing/UserContext";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import { updateApplicantReviewScore } from "../../utils/applicantFunctions";

interface ReviewStarsProps {
  applicant: ApplicantInt;
  source: "company" | "job";
  readOnly?: boolean;
}

function ReviewStars({
  applicant,
  source,
  readOnly = false,
}: ReviewStarsProps) {
  const { setSelectedCompanyApplicant, setSelectedJobApplicant } =
    useApplicants();
  const [tempRating, setTempRating] = useState<number | null>(null);
  const { updateApplicant } = useApplicants();
  const { userData } = useUser();

  // Reset tempRating when applicant changes
  useEffect(() => {
    setTempRating(null);
  }, [applicant]);

  const handleRatingChange = (rating: number) => {
    setTempRating(rating);
  };

  const handleSubmit = async () => {
    if (!tempRating || !userData?.user) return;

    const existingReviews = applicant.review || [];
    const userReviewIndex = existingReviews.findIndex(
      (review) => review.id === userData.user
    );

    let updatedReviews;
    if (userReviewIndex >= 0) {
      updatedReviews = [...existingReviews];
      updatedReviews[userReviewIndex] = {
        id: userData.user,
        score: tempRating,
      };
    } else {
      updatedReviews = [
        ...existingReviews,
        {
          id: userData.user,
          score: tempRating,
        },
      ];
    }

    // Calculate new average
    const newAverage =
      updatedReviews.reduce((sum, review) => sum + review.score, 0) /
      updatedReviews.length;

    const updatedApplicant = {
      ...applicant,
      reviewAverageScore: newAverage,
      review: updatedReviews,
    };

    // Optimistically update UI first
    if (source === "company") {
      setSelectedCompanyApplicant(updatedApplicant);
    } else if (source === "job") {
      setSelectedJobApplicant(updatedApplicant);
    }

    // Then perform the backend updates
    try {
      await Promise.all([
        updateApplicant(updatedApplicant, source),
        updateApplicantReviewScore(
          applicant._id as string,
          tempRating,
          userData.user
        ),
      ]);
    } catch (error) {
      // If the update fails, revert to the original state
      if (source === "company") {
        setSelectedCompanyApplicant(applicant);
      } else if (source === "job") {
        setSelectedJobApplicant(applicant);
      }
      console.error("Failed to update review:", error);
      // Optionally show an error message to the user
    }

    setTempRating(null);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        {[1, 2, 3, 4, 5].map((star) => (
          <div
            key={star}
            onClick={() => !readOnly && handleRatingChange(star)}
            className={`${
              !readOnly && "cursor-pointer hover:scale-110"
            } transition-transform`}
          >
            {star <= (tempRating ?? (applicant.reviewAverageScore || 0)) ? (
              <StarIcon sx={{ fontSize: 20, color: "#FFD700" }} />
            ) : (
              <StarBorderIcon sx={{ fontSize: 20, color: "#FFD700" }} />
            )}
          </div>
        ))}
        {!readOnly && (
          <span className="text-sm text-gray-600 ml-2">
            ({applicant.review?.length || 0}{" "}
            {applicant.review?.length === 1 ? "reseña" : "reseñas"})
          </span>
        )}
      </div>
      {tempRating && tempRating !== applicant.reviewAverageScore && (
        <button
          onClick={handleSubmit}
          className="bg-laburo-green text-white px-2 py-0.5 rounded-md text-xs hover:bg-opacity-90 transition-colors w-fit self-start"
        >
          Enviar Calificación
        </button>
      )}
    </div>
  );
}

export default ReviewStars;
