import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/dashboard/positions.css";

import { JobInt } from "../../typescript/interfaces/JobInterface";
import JobPostingRow from "./JobPostingRow";
import { Link } from "react-router-dom";
import { CompanyInt } from "../../typescript/interfaces/CompanyInt";
import WorkIcon from "@mui/icons-material/Work";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useJobPositions } from "../routing/JobPositionsContext";
import JobEditPopUp from "./JobEditPopUp";
import ConfirmPopUp from "./tools/ConfirmPopUp";
import { deleteJobById } from "../../utils/jobsUtils";

interface PositionsProps {
  positions: JobInt[];
  getPositions: () => Promise<void>;
  postsAvailable: number;
  companyData: CompanyInt;
  refreshDashboard: () => void;
  setShowEraseJobAlert: React.Dispatch<React.SetStateAction<boolean>>;
  onJobSelect: (jobId: string) => void;
}

const initialFilters = {
  status: "",
  datePosted: "desc",
  applicants: "",
  text: "",
};

const TableLoadingRows = () => (
  <>
    {[1, 2, 3].map((row) => (
      <tr key={row} className="animate-pulse">
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="h-4 bg-gray-200 rounded w-48" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
          <div className="h-4 bg-gray-200 rounded w-32" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="h-4 bg-gray-200 rounded w-20" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
          <div className="h-4 bg-gray-200 rounded w-24" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="h-4 bg-gray-200 rounded w-16" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="h-4 bg-gray-200 rounded w-20" />
        </td>
      </tr>
    ))}
  </>
);

function Positions(props: PositionsProps) {
  const {
    fetchPositions: fetchPositionsWithFilters,
    positions: ContextPositions,
    isLoading,
    paginationData,
  } = useJobPositions();

  const [page, setPage] = useState(1);

  const limit = 10;
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const filterRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [showJobPopUp, setShowJobPopUp] = useState(false);
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [selectedJob, setSelectedJob] = useState<JobInt | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFilterDropdownOpen]);

  useEffect(() => {
    const toggleBodyScroll = (disable: boolean) => {
      document.body.style.overflow = disable ? "hidden" : "";
    };

    toggleBodyScroll(showJobPopUp || showConfirmPopUp);

    return () => {
      toggleBodyScroll(false);
    };
  }, [showJobPopUp, showConfirmPopUp]);

  const handleFilterChange = (filterKey: string, value: string) => {
    const newFilters = {
      ...filters,
      [filterKey]: value,
    };
    setFilters(newFilters);
    setPage(1);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (filterKey === "text") {
      if (value === "") {
        fetchPositionsWithFilters(newFilters, 1, limit);
      } else {
        timeoutRef.current = setTimeout(() => {
          fetchPositionsWithFilters(newFilters, 1, limit);
        }, 300);
      }
    } else {
      fetchPositionsWithFilters(newFilters, 1, limit);
    }
  };

  const handleClearFilters = () => {
    setFilters(initialFilters);
    setPage(1);
    fetchPositionsWithFilters(initialFilters, 1, limit);
  };

  const handleJobClick = (jobId: string) => {
    props.onJobSelect(jobId);
  };

  const handlePreviousPage = () => {
    const newPage = page - 1;
    setPage(newPage);
    fetchPositionsWithFilters(filters, newPage, limit);
  };

  const handleNextPage = () => {
    const newPage = page + 1;
    setPage(newPage);
    fetchPositionsWithFilters(filters, newPage, limit);
  };

  const handleEdit = (job: JobInt) => {
    setSelectedJob(job);
    setShowJobPopUp(true);
  };

  const handleDelete = (job: JobInt) => {
    setSelectedJob(job);
    setShowConfirmPopUp(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedJob) return;

    setIsDeleting(true);
    const deletionSuccess = await deleteJobById(
      selectedJob._id as string,
      props.companyData._id as string
    );

    if (deletionSuccess) {
      setShowConfirmPopUp(false);
      props.refreshDashboard();
    } else {
      setIsDeleting(false);
      props.setShowEraseJobAlert(true);
      setShowConfirmPopUp(false);
    }
  };

  return (
    <div className="w-full flex-1 h-full px-2.5">
      <div className="w-full flex flex-col h-full pb-5 max-w-[1000px] mx-auto">
        <div className="mb-6 p-4 bg-white rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <h1 className="text-2xl font-bold text-gray-900">
                Vacantes Publicadas
              </h1>
              <div className="flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-600">
                <WorkIcon sx={{ fontSize: 16 }} />
                <span>{props.positions.length}</span>
              </div>
            </div>
            <Link
              to="/post-job"
              className="bg-laburo-green text-white px-4 py-2 rounded-full hover:opacity-60"
            >
              Nueva Vacante
            </Link>
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mt-4">
            <div className="flex items-center gap-2">
              <span className="text-gray-600">Plan:</span>
              <span
                className={`subscription-type ${
                  props.companyData?.subscriptionType === "free"
                    ? "free"
                    : props.companyData?.subscriptionType === "starter"
                    ? "starter"
                    : props.companyData?.subscriptionType === "growth"
                    ? "growth"
                    : props.companyData?.subscriptionType === "pro"
                    ? "pro"
                    : ""
                }`}
              >
                {props.companyData
                  ? props.companyData.subscriptionType === "free"
                    ? "Gratuito"
                    : props.companyData.subscriptionType === "starter"
                    ? "Starter"
                    : props.companyData.subscriptionType === "growth"
                    ? "Growth"
                    : props.companyData.subscriptionType === "pro"
                    ? "Pro"
                    : props.companyData.subscriptionType
                  : "Cargando..."}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-gray-600">Publicaciones Disponibles:</span>
              <span className="laburo-green font-medium">
                {props.postsAvailable}
              </span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 mt-4">
            <div className="flex-1">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Buscar vacantes..."
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={filters.text}
                  onChange={(e) => handleFilterChange("text", e.target.value)}
                />
                <SearchIcon
                  className="absolute right-3 top-2.5 text-gray-400"
                  sx={{ fontSize: 20 }}
                />
              </div>
            </div>
            <div className="relative flex gap-2" ref={filterRef}>
              <button
                onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
                className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <FilterAltIcon sx={{ fontSize: 20 }} />
                <span>Filtros</span>
              </button>

              {(filters.status !== "" ||
                filters.datePosted !== "desc" ||
                filters.applicants !== "") && (
                <button
                  onClick={handleClearFilters}
                  className="w-full sm:w-auto flex items-center justify-center gap-2 px-3 py-2 text-gray-600 bg-gray-50 border border-gray-200 rounded-md hover:bg-gray-100 transition-colors text-sm"
                  aria-label="Limpiar filtros"
                >
                  <span>×</span>
                  <span>Limpiar</span>
                </button>
              )}

              {isFilterDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 z-[100]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="p-4 space-y-4">
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Estado
                      </label>
                      <select
                        value={filters.status}
                        onChange={(e) =>
                          handleFilterChange("status", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                      >
                        <option value="">Todos</option>
                        <option value="true">Activo</option>
                        <option value="false">Inactivo</option>
                      </select>
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Ordenar por fecha
                      </label>
                      <select
                        value={filters.datePosted}
                        onChange={(e) =>
                          handleFilterChange("datePosted", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                      >
                        <option value="desc">Más reciente</option>
                        <option value="asc">Más antiguo</option>
                      </select>
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Ordenar por aplicantes
                      </label>
                      <select
                        value={filters.applicants}
                        onChange={(e) =>
                          handleFilterChange("applicants", e.target.value)
                        }
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                      >
                        <option value="">Sin orden</option>
                        <option value="desc">Más aplicantes</option>
                        <option value="asc">Menos aplicantes</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex-1  overflow-hidden  bg-white flex flex-col">
          <div className=" overflow-auto  border border-gray-200 rounded-lg ">
            <table className="min-w-full divide-y divide-gray-200  ">
              <thead className="bg-gray-50 border-b border-gray-200 sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Vacante
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                    Lugar
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Aplicantes
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                    Publicado
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Activo
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {isLoading ? (
                  <TableLoadingRows />
                ) : ContextPositions && ContextPositions.length > 0 ? (
                  ContextPositions.map((pos, index) => (
                    <JobPostingRow
                      job={pos}
                      key={index}
                      getPositions={props.getPositions}
                      refreshDashboard={props.refreshDashboard}
                      companyData={props.companyData}
                      setShowEraseJobAlert={props.setShowEraseJobAlert}
                      onClick={() => handleJobClick(pos._id as string)}
                      onEdit={() => handleEdit(pos)}
                      onDelete={() => handleDelete(pos)}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 text-center">
                      ¡Añade una vacante para conseguir el mejor talento!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex items-center justify-between px-4 py-3 bg-white border border-gray-200 rounded-lg">
            <div className="flex items-center text-sm text-gray-700">
              <span>Mostrando</span>
              <span className="font-medium mx-1">
                {props.positions.length} de {paginationData.totalPositions}
              </span>
              <span>resultados</span>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handlePreviousPage}
                disabled={page === 1}
                className={`px-3 py-1 rounded-md text-sm ${
                  page === 1
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300"
                }`}
              >
                Anterior
              </button>
              <span className="px-3 py-1 bg-laburo-green text-white rounded-md text-sm">
                {page}
              </span>
              <button
                onClick={handleNextPage}
                disabled={page >= paginationData.totalPages}
                className={`px-3 py-1 rounded-md text-sm ${
                  page >= paginationData.totalPages
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300"
                }`}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
      {showJobPopUp && selectedJob && (
        <JobEditPopUp
          setJobPopUp={setShowJobPopUp}
          job={selectedJob}
          getPositions={props.getPositions}
        />
      )}
      {showConfirmPopUp && (
        <ConfirmPopUp
          question={
            isDeleting
              ? "Eliminando..."
              : "¿Estás seguro/a de que deseas eliminar esta vacante?"
          }
          onConfirm={handleConfirmDelete}
          onCancel={() => {
            setShowConfirmPopUp(false);
          }}
        />
      )}
    </div>
  );
}

export default Positions;
