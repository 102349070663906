import { WorkExperience } from "../../typescript/interfaces/AppInterface";
import "../../assets/styles/job-apply/jobExperienceSection.css";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StandardDropDown from "../standard/dropdown/StandardDropDown";
interface JobExperienceSectionProps {
  JobExperienceList: WorkExperience[];
  handleJobExperienceChange: (
    index: number,
    field: keyof WorkExperience,
    value: string | boolean
  ) => void;
  handleJobExperienceDelete: (index: number) => void;
  addExperience: () => void;
  yearsOfExperience: string;
  setYearsOfExperience: (value: string) => void;
}
function JobExperienceSection({
  JobExperienceList,
  handleJobExperienceChange,
  handleJobExperienceDelete,
  addExperience,
  yearsOfExperience,
  setYearsOfExperience,
}: JobExperienceSectionProps) {
  const experienceOptions = [
    { value: "menos_1", label: "Menos de 1 año" },
    { value: "1_3", label: "1-3 años" },
    { value: "3_5", label: "3-5 años" },
    { value: "5_10", label: "5-10 años" },
    { value: "mas_10", label: "Más de 10 años" },
  ];

  const handleInputChange = (
    index: number,
    field: keyof WorkExperience,
    value: string | boolean
  ) => {
    handleJobExperienceChange(index, field, value);
  };
  return (
    <div className="JobExperience-section postjob-gray-container">
      <div className="flx flx-center experience_education-add-button">
        <div
          className="JobExperience-section__title"
          style={{ fontSize: "18px" }}
        >
          Experiencia laboral
        </div>
        <div className="ex-ed-add-button" onClick={addExperience}>
          <AddIcon /> añadir
        </div>
      </div>
      <div className="mt-5 mb-5 p-4 bg-gray-50 rounded-lg">
        <div className="font-medium mb-2.5 text-gray-700">
          Años de experiencia
        </div>
        <div className="w-full">
          <StandardDropDown
            options={experienceOptions}
            value={{
              value: yearsOfExperience,
              label:
                experienceOptions.find((opt) => opt.value === yearsOfExperience)
                  ?.label || "",
            }}
            onSelect={(selected) => setYearsOfExperience(selected.value)}
          />
        </div>
      </div>
      <div className="JobExperience-section__items">
        {JobExperienceList.map((comp, index) => (
          <div key={index} className="JobExperience-item">
            {/* pass index to erase  */}
            <div
              className="JobExperience-item__delete-button button-hover"
              onClick={() => handleJobExperienceDelete(index)}
            >
              <DeleteOutlineIcon />
            </div>
            <div>
              <div>Empresa</div>
              <input
                className="JobExperience-input-item search-pill-input JobExperience-item__company"
                value={comp.empresa}
                onChange={(e) =>
                  handleInputChange(index, "empresa", e.target.value)
                }
              />
            </div>
            <div>
              <div>Puesto</div>
              <input
                className=" JobExperience-input-item JobExperience-item__title search-pill-input"
                value={comp.puesto}
                onChange={(e) =>
                  handleInputChange(index, "puesto", e.target.value)
                }
              />
            </div>
            <div>
              <div>Descripción</div>
              <textarea
                className="JobExperience-input-item JobExperience-item__description search-pill-input"
                value={comp.descripcion}
                onChange={(e) =>
                  handleInputChange(index, "descripcion", e.target.value)
                }
              />
            </div>
            <div className=" JobExperience-item__dates-container">
              <div className="flx" style={{ gap: "10px" }}>
                <div className="flx flx-col">
                  <div>Fecha de inicio</div>
                  <DatePicker
                    value={comp.fecha_inicio ? dayjs(comp.fecha_inicio) : null}
                    onChange={(newValue) =>
                      handleInputChange(
                        index,
                        "fecha_inicio",
                        newValue?.format("YYYY-MM-DD") || ""
                      )
                    }
                  />
                </div>
                <div className="flx flx-col ">
                  <div>Fecha de fin</div>
                  {comp.trabajando_actualmente ? (
                    <div className="date-picker-placeholder">Presente</div>
                  ) : (
                    <DatePicker
                      value={comp.fecha_fin ? dayjs(comp.fecha_fin) : null}
                      onChange={(newValue) =>
                        handleInputChange(
                          index,
                          "fecha_fin",
                          newValue?.format("YYYY-MM-DD") || ""
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div
                className="flx "
                style={{ alignItems: "center", gap: "10px" }}
              >
                {"Actualmente trabajando"}
                {comp.trabajando_actualmente ? (
                  <div
                    className="tof-dot-true button-hover"
                    onClick={() => {
                      handleInputChange(index, "trabajando_actualmente", false);
                    }}
                  >
                    {" "}
                    <CheckOutlinedIcon
                      style={{ color: "white", fontSize: "18px" }}
                    />{" "}
                  </div>
                ) : (
                  <div
                    className="tof-question-dot button-hover"
                    onClick={() => {
                      handleInputChange(index, "trabajando_actualmente", true);
                    }}
                  >
                    {" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default JobExperienceSection;
