import React, { useEffect, useState } from "react";
import {
  AutomationAction,
  AutomationActionType,
} from "../../../typescript/interfaces/AutomationTypes";
import {
  applicantStateOption,
  JobInt,
} from "../../../typescript/interfaces/JobInterface";
import EmailAutomationTemplate from "./templates/EmailAutomationTemplate";
import MovementAutomationTemplate from "./templates/MovementAutomationTemplate";
import ConditionSection from "./templates/ConditionSection";
import { getAutomationTrigger } from "../../../utils/automation/getAutomationTrigger";
import LoadingWidget from "../../widgets/LoadingWidget";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

interface AutomationFormsProps {
  stateOptions: applicantStateOption[];
  onClose: () => void;
  onSubmit: (
    automation: {
      stateId: string;
      actions: AutomationAction[];
    },
    exists: boolean,
    currTriggerId: string
  ) => Promise<void>;
  selectedJob: JobInt;
  currentStateId: string;
}

const AutomationForms: React.FC<AutomationFormsProps> = ({
  stateOptions,
  onClose,
  onSubmit,
  selectedJob,
  currentStateId,
}) => {
  const [selectedState, setSelectedState] = useState(currentStateId);
  const [actions, setActions] = useState<AutomationAction[]>([]);
  const [expandedActions, setExpandedActions] = useState<Set<number>>(
    new Set()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originalState, setOriginalState] = useState("");
  const [originalActions, setOriginalActions] = useState<AutomationAction[]>(
    []
  );
  const [currTriggerId, setCurrTriggerId] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [exists, setExists] = useState(false);
  const iconsMap = {
    MOVE_APPLICANT: {
      icon: <DriveFileMoveOutlinedIcon style={{ fontSize: "23px" }} />,
      label: "Mover Candidato",
      description:
        "Mueve automáticamente al candidato a otro estado del proceso.",
    },
    SEND_EMAIL: {
      icon: <EmailOutlinedIcon style={{ fontSize: "22px" }} />,
      label: "Enviar Email",
      description:
        "Envía un correo electrónico automático basado en una plantilla.",
    },
  };
  const addAction = () => {
    const newAction: AutomationAction = {
      type: "MOVE_APPLICANT",
      config: {
        recipientType: "APPLICANT",
      },
      order: actions.length,
    };
    setActions([...actions, newAction]);
  };

  const updateAction = (index: number, updates: Partial<AutomationAction>) => {
    const newActions = [...actions];
    newActions[index] = { ...newActions[index], ...updates };
    setActions(newActions);
  };

  const removeAction = (index: number) => {
    setActions(actions.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // small validation

    setShowSubmitConfirm(true);
  };

  const confirmSubmit = async () => {
    setShowSubmitConfirm(false);
    setIsSubmitting(true);
    try {
      await onSubmit(
        {
          stateId: selectedState,
          actions: actions,
        },
        exists,
        currTriggerId
      );
      setIsSubmitting(false);

      //   update the props.stateOptions locally
    } catch (error) {
      console.error("Error submitting automation:", error);
    }
  };

  const toggleActionExpansion = (index: number, e: React.MouseEvent) => {
    if ((e.target as HTMLElement).closest(".action-content")) {
      return;
    }
    setExpandedActions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const handleDeleteAction = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    removeAction(index);
  };

  useEffect(() => {
    const getAutomationTriggers = async () => {
      if (!selectedJob._id || !selectedState) {
        return;
      }
      setIsLoading(true);
      try {
        const triggers = await getAutomationTrigger(
          selectedJob._id,
          selectedState
        );
        setExists(!!triggers);
        setCurrTriggerId(triggers?.id || "");
        let triggeredActions = triggers?.actions || [];
        // if (triggeredActions) {
        //   triggeredActions = { ...triggeredActions, id: triggers?._id };
        // }
        setActions(triggeredActions);
        setOriginalState(selectedState);
        setOriginalActions(triggeredActions);
        setHasChanges(false);
      } catch (error) {
        console.error("Error fetching triggers:", error);
        setActions([]);
        setOriginalActions([]);
      } finally {
        setIsLoading(false);
      }
    };
    getAutomationTriggers();
  }, [selectedState, selectedJob, stateOptions]);

  useEffect(() => {
    const stateChanged = selectedState !== originalState;
    const actionsChanged =
      JSON.stringify(actions) !== JSON.stringify(originalActions);
    setHasChanges(stateChanged || actionsChanged);
  }, [selectedState, actions, originalState, originalActions]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      {isSubmitting && (
        <div className="fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center z-[102]">
          <LoadingWidget loading={true} />
        </div>
      )}

      <div className="bg-white rounded-lg p-6 max-w-2xl w-full h-[90vh] flex flex-col">
        <div className="flex justify-between items-center border-b border-gray-200 pb-4">
          <div className="space-y-1">
            <h2 className="text-xl font-semibold text-gray-900">
              Configurar Automatización
            </h2>
            <p className="text-sm text-gray-500">
              Configure las acciones automáticas para este estado
            </p>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Cerrar"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col flex-1 overflow-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent pr-2 pt-6"
        >
          <div className="flex-1  space-y-6  ">
            {/* State Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Estado Activador
              </label>
              <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="">Seleccionar estado</option>
                {stateOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              {selectedState && (
                <p className="text-sm text-gray-500 mt-1">
                  Esta acción se ejecutará cuando los usuarios lleguen al estado{" "}
                  {
                    stateOptions.find((option) => option.id === selectedState)
                      ?.name
                  }
                </p>
              )}
            </div>

            {/* Actions List */}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-700">
                  Acciones
                </label>
                <button
                  type="button"
                  onClick={addAction}
                  className="px-3 py-1 text-sm text-white bg-laburo-green rounded-md hover:bg-emerald-600"
                >
                  + Agregar Acción
                </button>
              </div>

              {isLoading ? (
                <div className="flex justify-center items-center py-8">
                  <LoadingWidget loading={true} />
                </div>
              ) : (
                actions.map((action, index) => (
                  <div
                    key={index}
                    onClick={(e) => toggleActionExpansion(index, e)}
                    className="p-4 border border-gray-200 rounded-lg space-y-4 relative cursor-pointer"
                  >
                    <button
                      type="button"
                      onClick={(e) => handleDeleteAction(index, e)}
                      className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>

                    <div className="flex items-center gap-4 pb-3 border-b border-gray-100 cursor-pointer">
                      <svg
                        className={`w-4 h-4 text-gray-400 transition-transform hover:text-gray-600 cursor-pointer ${
                          expandedActions.has(index) ? "rotate-90" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                      <div className="flex items-center gap-2 flex-1">
                        <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-sm font-medium">
                          {index + 1}
                        </span>
                        <h3 className="font-medium flex items-center gap-1">
                          {iconsMap[action.type].label}{" "}
                          {iconsMap[action.type].icon}
                        </h3>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 ml-8 py-2">
                      {iconsMap[action.type].description}
                    </div>

                    {/* Expandable content with better section separation */}
                    {expandedActions.has(index) && (
                      <div className="action-content ml-8 space-y-6 pt-2">
                        {/* Action Type Selection */}
                        <div className="p-4 bg-gray-50 rounded-lg space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Tipo de Acción
                          </label>
                          <select
                            value={action.type}
                            onChange={(e) =>
                              updateAction(index, {
                                type: e.target.value as AutomationActionType,
                              })
                            }
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                          >
                            <option value="MOVE_APPLICANT">
                              Mover Candidato
                            </option>
                            <option value="SEND_EMAIL">Enviar Correo</option>
                          </select>
                        </div>

                        {/* Action Configuration */}
                        <div className="p-4 bg-gray-50 rounded-lg">
                          {action.type === "MOVE_APPLICANT" && (
                            <MovementAutomationTemplate
                              action={action}
                              updateAction={(updates) =>
                                updateAction(index, updates)
                              }
                              stateOptions={stateOptions}
                              selectedTriggerId={selectedState}
                            />
                          )}

                          {action.type === "SEND_EMAIL" && (
                            <EmailAutomationTemplate
                              action={action}
                              updateAction={(updates) =>
                                updateAction(index, updates)
                              }
                            />
                          )}
                        </div>

                        {/* Condition Section */}
                        <ConditionSection
                          action={action}
                          index={index}
                          updateAction={(updates) =>
                            updateAction(index, updates)
                          }
                          selectedJob={selectedJob}
                        />
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Submit Button - Outside of scroll area */}
          <div className="flex justify-end gap-3 pt-4 mt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={isSubmitting || isLoading || !hasChanges}
              className="px-4 py-2 text-white bg-laburo-green rounded-md hover:bg-emerald-600 disabled:opacity-70 disabled:cursor-not-allowed"
            >
              {exists
                ? isSubmitting
                  ? "Actualizando Automatización"
                  : "Actualizar Automatización"
                : isSubmitting
                ? "Guardando Automatización"
                : "Guardar Automatización"}
            </button>
          </div>
        </form>
      </div>

      {showSubmitConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[101]">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-medium text-gray-900">
              Confirmar cambios
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              ¿Estás seguro de que deseas {exists ? "actualizar" : "guardar"}{" "}
              esta automatización?
            </p>
            <div className="mt-4 flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setShowSubmitConfirm(false)}
                className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={confirmSubmit}
                className="px-4 py-2 text-white bg-laburo-green rounded-md hover:bg-emerald-600"
              >
                {exists ? "Actualizar" : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutomationForms;
