import React, { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

interface ProfileSettingsProps {
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  onLogout: () => void;
}

function ProfileSettings({
  firstName,
  lastName,
  avatarUrl,
  onLogout,
}: ProfileSettingsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const hasInitials = firstName && lastName;
  const initials = hasInitials ? firstName.charAt(0) + lastName.charAt(0) : "";

  return (
    <div className="relative" ref={containerRef}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="flex items-center gap-3 px-3 py-2 rounded-lg hover:bg-gray-100"
      >
        {firstName && (
          <span className="text-sm font-medium">Hola, {firstName}</span>
        )}

        {avatarUrl ? (
          <img
            src={avatarUrl}
            alt={firstName}
            className="w-8 h-8 rounded-full object-cover"
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white text-sm font-medium">
            {hasInitials ? (
              initials
            ) : (
              <PersonIcon style={{ fontSize: "18px" }} />
            )}
          </div>
        )}

        <ExpandMoreIcon
          style={{ fontSize: "18px", fontWeight: "bold" }}
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 py-2 px-2 bg-white rounded-lg shadow-lg border border-gray-100 ">
          <button
            onClick={onLogout}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:rounded-md flex items-center justify-between"
          >
            Cerrar sesión
            <LogoutIcon style={{ fontSize: "18px" }} />
          </button>
        </div>
      )}
    </div>
  );
}

export default ProfileSettings;
