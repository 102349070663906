import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { CompanyInt } from "../../typescript/interfaces/CompanyInt";
import { BusinessUserInt } from "../../typescript/interfaces/BusinessUserInt";

interface UserContextType {
  signedIn: boolean;
  setSignedIn: (signedIn: boolean) => void;
  userData: BusinessUserInt | null;
  companyData: CompanyInt | null;
  setUserData: (user: BusinessUserInt | null) => void;
  setCompanyData: (company: CompanyInt | null) => void;
  clearUserData: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
  const [userData, setUserData] = useState<BusinessUserInt | null>(null);
  const [companyData, setCompanyData] = useState<CompanyInt | null>(null);
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const clearUserData = () => {
    setUserData(null);
    setCompanyData(null);
    localStorage.removeItem("userData");
    localStorage.removeItem("companyData");
  };

  // Load data from localStorage on initial mount
  useEffect(() => {
    const savedUser = localStorage.getItem("userData");
    const savedCompany = localStorage.getItem("companyData");

    if (savedUser !== null) {
      try {
        setUserData(JSON.parse(savedUser));
      } catch (error) {
        console.error("Error parsing userData from localStorage", error);
      }
    }

    if (savedCompany !== null) {
      try {
        setCompanyData(JSON.parse(savedCompany));
      } catch (error) {
        console.error("Error parsing companyData from localStorage", error);
      }
    }
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    if (userData !== null) {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (companyData !== null) {
      localStorage.setItem("companyData", JSON.stringify(companyData));
    }
  }, [companyData]);

  return (
    <UserContext.Provider
      value={{
        signedIn,
        setSignedIn,
        userData,
        companyData,
        setUserData,
        setCompanyData,
        clearUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

// Custom hook to use the context
export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
